import { useEffect, useState } from 'react'
import { CloseIcon, HamburgerIcon, SendIcon } from '../../../assets/icons'
import {
  ActionButton,
  AudioComponent,
  Video,
  UserAvatar,
  ChatScreen,
  MenuOverlay,
  AccessIcon,
} from '../../Components'
import { useLocation } from 'react-router-dom'

const VideoScreen = ({
  dataSource,
  currentUserVideo,
  handleOtherUserAudio,
  chatMessages,
  message,
  onTypeMessage,
  isSendingMessage,
  sendMessage,
  closeChat,
  chatButton,
  handleLayout,
  splitMenuOverlay = false,
  currentUserType,
  isMediaTrackAvailable,
}: any) => {
  const userVideo = [...dataSource.otherUsers, dataSource?.currentMedia]
  const userType = ['PATIENT', 'GUARDIAN']
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const user: any = query.get('user-type')
  const [showMenuOverlay, setShowMenuOverlay] = useState<boolean>(false)
  const [activeUser, setActiveUser] = useState<any>(0)

  const handleActionButtons = (currentUser: any, index: any, name: any) => {
    if (name === 'pin') {
      setActiveUser(index)
    }
  }
  const handleMenuOverlay = () => {
    setShowMenuOverlay(!showMenuOverlay)
    handleLayout(!showMenuOverlay, 'videoScreen')
  }
  const handleCloseMenuOverLay = () => {
    setShowMenuOverlay(!showMenuOverlay)
  }
  const handleCloseChat = () => {
    closeChat({ name: 'chat' })
  }
  function chatMessagesAsList() {
    let messages = []
    for (const messageKey in chatMessages) {
      messages.push(chatMessages[messageKey])
    }
    return messages
  }
  console.log(chatButton, 'chatButton')
  localStorage.setItem('chat', chatButton)

  return (
    <section className="h-full w-full relative">
      {/* {currentMedia } */}
      {showMenuOverlay ? (
        <div className="w-[70%] h-full bg-green-400 rounded-xl">
          <MenuOverlay closeMenuOverLay={handleCloseMenuOverLay} />
        </div>
      ) : (
        <>
          <div
            className=" h-full w-full group border-2
       border-[#808080] rounded-2xl"
          >
            {userType?.includes(userVideo[0]?.userType) &&
              userVideo[activeUser]?.userType === 'PATIENT' && (
                <AccessIcon
                  role={currentUserType}
                  action={'hamburger'}
                  userType={userVideo[0]?.userType}
                >
                  <button
                    className="absolute left-3 top-2 md:left-6 md:top-5 z-10 text-sm font-medium text-white "
                    onClick={handleMenuOverlay}
                  >
                    <HamburgerIcon className=" mix-blend-multiply h-[30px] w-[30px] z-10" />
                  </button>
                </AccessIcon>
              )}

            <ActionButton
              currentUserType={currentUserType}
              className={`bg-white absolute hidden right-3 top-[10px] group-hover:flex z-10`}
              onClick={(name: any) =>
                handleActionButtons(userVideo[activeUser], activeUser, name)
              }
              active={userVideo[activeUser]}
              vertical={false}
            />
            <>
              {userVideo.length === 1 ? (
                userVideo[activeUser]?.current?.mediaStream ? (
                  <Video
                    className=" object-cover h-full w-full rounded-xl "
                    muted={true}
                    srcObject={
                      currentUserVideo
                        ? userVideo[activeUser]?.current?.mediaStream
                        : null
                    }
                    streamPaused={!isMediaTrackAvailable?.video?.action}
                    userName={userVideo[activeUser]?.userName}
                  />
                ) : (
                  <div className=" flex h-full items-center justify-center">
                    <UserAvatar
                      name={userVideo[activeUser]?.userName}
                      className="h-[126px] w-[123px] rounded-full bg-white text-3xl font-bold"
                    />
                  </div>
                )
              ) : (
                <>
                  {activeUser === 1 ? (
                    userVideo[activeUser]?.current?.mediaStream ? (
                      <Video
                        className=" object-cover h-full w-full rounded-xl "
                        muted={true}
                        srcObject={
                          currentUserVideo
                            ? userVideo[activeUser]?.current?.mediaStream
                            : null
                        }
                        streamPaused={!isMediaTrackAvailable?.video?.action}
                        userName={userVideo[activeUser]?.userName}
                      />
                    ) : (
                      <div className=" flex h-full items-center justify-center">
                        <UserAvatar
                          name={userVideo[activeUser]?.userName}
                          className="h-[126px] w-[123px] rounded-full bg-white text-3xl font-bold"
                        />
                      </div>
                    )
                  ) : userVideo[activeUser]?.medias?.video?.stream ||
                    userVideo[activeUser]?.medias?.audio?.stream ? (
                    <>
                      <Video
                        className="object-cover h-full w-full rounded-xl "
                        muted={true}
                        srcObject={userVideo[activeUser]?.medias?.video?.stream}
                        streamPaused={
                          userVideo[activeUser]?.medias?.video?.paused
                        }
                        userName={userVideo[activeUser]?.userName}
                      />
                      <AudioComponent
                        srcObject={userVideo[activeUser].medias?.audio?.stream}
                        muted={userVideo[activeUser].medias?.audio?.paused}
                      />
                    </>
                  ) : (
                    <div className=" flex h-full items-center justify-center">
                      <UserAvatar
                        name={userVideo[activeUser]?.userName}
                        className="h-[126px] w-[123px] rounded-full bg-white text-3xl font-bold"
                      />
                    </div>
                  )}
                </>
              )}
              <p
                className="text-xs md:text-base lg:text-base font-medium md:font-bold lg:font-bold text-white absolute bottom-3 left-2 
                opacity-[0.75] truncate md:max-w-[172px] max-w-[115px]"
              >
                {` ${userVideo[activeUser]?.userType} :  ${userVideo[activeUser]?.userName}`}
              </p>
            </>
          </div>
        </>
      )}

      {/* {currentMedia } */}

      {/* {otherUsers } */}
      {userVideo.length > 1 &&
        userVideo?.map((userVideoData: any, index: any) => {
          if (showMenuOverlay ? activeUser === index : activeUser === '')
            return null
          if (index === activeUser) return null
          const muteAudio =
            userVideoData.medias?.audio?.paused === true ? true : false

          const videoSourceObject =
            index === 1
              ? userVideoData.current?.mediaStream
              : userVideoData.medias?.video?.stream

          const streamPaused =
            index === 1
              ? !isMediaTrackAvailable?.video?.action
              : userVideoData.medias?.video?.paused

          const audioSourceObject = userVideoData.medias?.audio?.stream
          return (
            <section
              className="absolute bottom-3 right-4 rounded-xl bg-[rgba(230,233,230,0.3)] border-2
              border-[#808080] md:h-[230px] md:w-[230px] lg:h-[330px] lg:w-[330px] z-10 group"
            >
              {userType?.includes(userVideo[0]?.userType) &&
                userVideo[index]?.userType === 'PATIENT' && (
                  <AccessIcon
                    role={currentUserType}
                    action={'hamburger'}
                    userType={userVideo[0]?.userType}
                  >
                    <button
                      className="absolute left-3 top-2 md:left-6 md:top-5 z-10 text-sm font-medium text-white "
                      onClick={handleMenuOverlay}
                    >
                      <HamburgerIcon className=" mix-blend-multiply h-[30px] w-[30px] z-10" />
                    </button>
                  </AccessIcon>
                )}
              <ActionButton
                currentUserType={currentUserType}
                className={`bg-white absolute hidden right-3 top-[10px] group-hover:flex z-10`}
                onClick={(name: any) =>
                  handleActionButtons(userVideoData, index, name)
                }
                active={userVideoData}
                vertical={false}
              />
              <Video
                className="object-cover md:h-[200px] md:w-[200px] lg:h-[300px] lg:w-[300px] h-[150px] w-[150px]"
                muted={true}
                srcObject={videoSourceObject}
                streamPaused={streamPaused}
                userName={userVideoData?.userName}
              />
              <AudioComponent srcObject={audioSourceObject} muted={muteAudio} />
              <p
                className="text-xs md:text-base lg:text-base font-medium md:font-bold lg:font-bold text-white absolute bottom-3 left-2 
              opacity-[0.75] truncate md:max-w-[172px] max-w-[115px]"
              >
                {userVideoData?.userType} : {userVideoData?.userName}
              </p>
            </section>
          )
        })}
      {/* {otherUsers } */}

      {/* {Chat Box } */}
      {chatButton && (
        <section
          className={`${
            userType.includes(user)
              ? 'lg:h-[90%] lg:top-[20px]'
              : 'lg:h-[85%] lg:top-16'
          } absolute left-0 top-0 lg:left-6  md:left-6 md:top-6 h-full sm:h-[90%]  
          w-full  lg:w-[25%] sm:w-[40%] ease-in duration-300`}
        >
          <div
            className="bg-[rgba(38,50,60,0.80)] w-full h-full rounded-lg border-2 flex flex-col 
              justify-between p-5"
          >
            <button
              className="flex justify-end pr-1"
              onClick={() => handleCloseChat()}
            >
              <CloseIcon className="h-5 w-5" fill="white" />
            </button>
            <section className="flex-1 overflow-y-auto ">
              {chatMessagesAsList().map((message: any) => {
                return <ChatScreen message={message} key={message.messageId} />
              })}
            </section>

            <div className="justify-self-end flex justify-between items-center gap-1">
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight 
                  focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="your message here"
                value={message}
                onChange={(event) => onTypeMessage(event.target.value)}
              />

              {isSendingMessage === true ? (
                <div className="send-message-loader absolute top-2 right-2 cursor-pointer" />
              ) : (
                <button onClick={sendMessage}>
                  <SendIcon
                    fill="white"
                    className="absolute top-1 right-1 cursor-pointer hover:scale-110 "
                  />
                </button>
              )}
            </div>
          </div>
        </section>
      )}
      {/* {Chat Box } */}
    </section>
  )
}

export default VideoScreen
