interface UserDetailCardType {
  title: string
  value: string
}

const UserDetailCard = ({ title, value }: UserDetailCardType) => {
  return (
    <div className="flex flex-col ">
      <span className="md:text-sm lg:text-sm text-xs font-normal text-[#59636C]">
        {title}
      </span>
      <span
        className="md:text-sm lg:text-sm text-xs font-medium text-black
       md:text-[#26323C] lg:text-[#26323C] md:font-bold lg:font-bold"
      >
        {value}
      </span>
    </div>
  )
}

export default UserDetailCard
