import { useNavigate } from "react-router-dom";

export default function SomethingWentWrong(){
  const navigator = useNavigate()
  return (
        <div className="h-full w-full flex justify-center items-center" >
            <div className="flex flex-col items-center" >
            <h1 className='text-3xl font-mono text-slate-500 mb-4 text-center' > Something wrong, unable to re-connect </h1>
            <button className="bg-blue-700 shadow-lg rounded-lg p-2 m-3 text-white " onClick={()=>{
                    navigator('/',{replace:true})
                }} >Go back to Home</button>
            </div>
        </div>
    )
}