import { useEffect, useRef, useState } from 'react'
import CONDITIONAL_COMP from '../../components/Conditional'
import VideoLayout from '../VideoLayout'
import { Video, VideoOff, Voice, VoiceOff } from '../../assets/icons'
import { BackgroundImage, DoctorImage } from '../../assets/images'
import useCall from './useCall'
import useQuery from '../../hook/query'
import { generateRandomUUID, getRandomTwoDigitNumber } from './utils'
import { UrlParamsDataType, UserTypesType } from './types'
import { stateType } from '../../types'
import './call.css'
import { MEDIASOUP_SOCKET_ENDPOINT } from './configs'

type Props = {}

const ENDPOINT = MEDIASOUP_SOCKET_ENDPOINT

function Call({ }: Props) {
  const [allUsers, setAllusers] = useState<any>({})
  const [user, setUser]: stateType<UrlParamsDataType> =
    useState<UrlParamsDataType>({
      userId: '',
      userName: '',
      userType: 'DOCTOR',
      callId: null,
    })
  const query = useQuery()
  const {
    leaveCall,
    joinOrCreateCall,
    toggleVideo,
    toggleAudio,
    videoCallMetaData,
    isMediaTrackAvailable,
    onSocketDisconnect,
    otherUsers,
    mediaTrack,
    setVideoCallMetaData,
    toggleOtherUserAudio,
    chatMessages,
    message,
    onTypeMessage,
    isSendingMessage,
    sendMessage,
    isCallConnecting,
    muteOtherUserAudio,
    removePeerById,
    isNetworkIssue,
  } = useCall()

  useEffect(() => {
    // url 1 : http://localhost:4000/call?user-id=123&user-type=DOCTOR&user-name=doctor-1&Appointment-id=call123
    // url 2 : http://localhost:4000/call?user-id=456&user-type=PATIENT&user-name=patient-1&Appointment-id=call123
    if (!query) return
    let _user: UrlParamsDataType = {
      userId: '',
      userName: '',
      userType: 'DOCTOR',
      callId: null,
    }
    if (query.get('user-id')) {
      _user.userId = query.get('user-id')
        ? (query.get('user-id') as string)
        : generateRandomUUID()
    }
    if (query.get('user-type')) {
      _user.userType = query.get('user-type')
        ? (query.get('user-type') as UserTypesType)
        : 'DOCTOR'
    }
    if (query.get('user-name')) {
      _user.userName = query.get('user-name')
        ? (query.get('user-name') as string)
        : `user-${getRandomTwoDigitNumber()}`
    }
    if (query.get('Appointment-id')) {
      _user.callId = query.get('Appointment-id')
        ? (query.get('Appointment-id') as string)
        : null
    }
    setUser(_user)
  }, [query])

  useEffect(() => {
    if (user && user.userName && user.userId && user.userType) {
      joinOrCreateCall({
        userType: user.userType,
        userId: user.userId,
        userName: user.userName,
        callId: user.callId,
      })
    }
  }, [user])

  useEffect(() => {
    const currentMediaTrack = {
      ...mediaTrack,
      userName: user.userName,
      userId: user.userId,
      userType: user.userType,
      callId: user.callId,
      isAudioPaused: isMediaTrackAvailable?.audio.action,
      isVideoPaused: isMediaTrackAvailable?.video.action,
    }

    let streamOptions = otherUsers?.map((otherUser) => {
      const streamOption = {
        ...otherUser,
        isAudioPaused:
          otherUser.medias?.audio?.stream &&
            otherUser.medias?.audio?.paused === false
            ? true
            : false,
        isVideoPaused:
          otherUser.medias?.video?.stream &&
            otherUser.medias?.video.paused === false
            ? true
            : false,
      }
      return streamOption
    })

    const userStreamData = {
      currentMedia: currentMediaTrack,
      otherUsers: streamOptions || [],
    }
    setAllusers(userStreamData)
  }, [user, otherUsers, mediaTrack, isMediaTrackAvailable, videoCallMetaData])

  function copyText() {
    navigator.clipboard.writeText(videoCallMetaData?.callId)
  }

  const USER_CONTAINS_CALLID_PEERID =
    videoCallMetaData.callId.trim().length > 4 &&
    videoCallMetaData.peerId.trim().length > 4
  const SHOW_CURRENT_USER_VIDEO =
    isMediaTrackAvailable.video.action === true &&
      isMediaTrackAvailable.video.device &&
      isMediaTrackAvailable.mediaStream
      ? true
      : false

  return (
    <>
      {isNetworkIssue === true && (
        <section className="h-full w-full fixed z-6 flex justify-center items-center">
          <main className="flex justify-center flex-col items-center">
            <h3 className="text-3xl font-mono text-slate-500 mb-4 text-center">
              Oops! No internet connection
            </h3>
            <section className="h-[105px] w-[105px] flex justify-center items-center">
              <div className="sand-time-loader" />
            </section>
            <h1 className="text-2xl font-mono text-slate-500 mt-4 text-center">
              Trying to Re-connect...
            </h1>
          </main>
        </section>
      )}
      <CONDITIONAL_COMP show={USER_CONTAINS_CALLID_PEERID}>
        <VideoLayout
          chatMessages={chatMessages}
          message={message}
          onTypeMessage={onTypeMessage}
          isSendingMessage={isSendingMessage}
          sendMessage={sendMessage}
          userName={user.userName}
          copyText={copyText}
          AppointmentId={videoCallMetaData.callId}
          currentUserVideo={SHOW_CURRENT_USER_VIDEO}
          dataSource={allUsers}
          handleVideoToggle={toggleVideo}
          handleAudioToggle={toggleAudio}
          handleOtherUserAudio={toggleOtherUserAudio}
          isMediaTrackAvailable={isMediaTrackAvailable}
          handleLeaveCall={leaveCall}
          muteOtherUserAudio={muteOtherUserAudio}
          removePeerById={removePeerById}
          userType={user.userType}
        />
      </CONDITIONAL_COMP>
    </>
  )
}

export default Call
