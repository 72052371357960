import {
  Dizziness,
  ChestPain,
  ConicalPain,
  MouthUlcers,
} from '../assets/images'

export const SymptomsData = [
  {
    key: 1,

    symptomsImages: [
      {
        image: ChestPain,
        name: 'Dizziness',
      },
    ],
    title: 'Description',
    description:
      'Chronic pain is pain that lasts for over three months. The pain can be there all the time, or it may come and go. It can happen anywhere in your body. Chronic pain can interfere with your daily activities, such as working, having a social life and taking care of yourself or others.',
  },
  {
    key: 2,

    symptomsImages: [
      {
        image: ConicalPain,
        name: 'Dizziness',
      },
    ],
    title: 'Description',
    description:
      'Chronic pain is pain that lasts for over three months. The pain can be there all the time, or it may come and go. It can happen anywhere in your body. Chronic pain can interfere with your daily activities, such as working, having a social life and taking care of yourself or others.',
  },
  {
    key: 3,

    symptomsImages: [
      {
        image: ChestPain,
        name: 'Dizziness',
      },
    ],
    title: 'Description',
    description:
      'Chest pain is pain or discomfort in the chest, typically the front of the chest.[1] It may be described as sharp, dull, pressure, heaviness or squeezing.[3] Associated symptoms may include pain in the shoulder, arm, upper abdomen, or jaw, along with nausea, sweating, or shortness of breath.',
  },
  {
    key: 4,

    symptomsImages: [
      {
        image: MouthUlcers,
        name: 'Dizziness',
      },
    ],
    title: 'Description',
    description:
      'A mouth ulcer is a sore that develops in the soft tissue lining of your gums, tongue, inner cheeks, lips or palate. They’re usually yellow or red, and they can be quite painful. Mouth ulcers may also be called aphthous ulcers or canker sores. Cleveland Clinic is a non-profit academic medical center.',
  },
]
