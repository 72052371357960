import { MuteIcon, PinIcon, RemoveIcon } from '../assets/icons'

export const ActionButtonsData = [
  {
    key: 1,
    icon: <MuteIcon className="h-4 w-[17px] cursor-pointer" />,
    name: 'mute',
  },
  {
    key: 3,
    icon: <PinIcon className="h-4 w-[17px] cursor-pointer" />,
    name: 'pin',
  },
  {
    key: 2,
    icon: <RemoveIcon className="h-4 w-[17px] cursor-pointer" />,
    name: 'remove',
  },
]
