const MedicalImageCard = ({ source, name }: any) => {
  return (
    <section>
      <div className="md:h-[90%] lg:h-[90%] h-[50%] w-full relative rounded-2xl">
        <img className="w-full h-full" src={source}></img>
        <span className="text-white text-xs md:text-sm lg:text-base font-medium md:font-bold lg:font-bold absolute bottom-1 left-[12px] ">
          {name}
        </span>
      </div>
    </section>
  )
}

export default MedicalImageCard
