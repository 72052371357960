import { VideoHTMLAttributes, useEffect, useRef } from 'react'
import UserAvatar from '../UserAvatar'

type PropsType = VideoHTMLAttributes<HTMLVideoElement> & {
  srcObject: MediaStream | undefined | null,
  streamPaused: boolean,
  userName?: string
}

export default function Video({ srcObject, streamPaused = true, userName, ...props }: PropsType) {
  const refVideo = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (!refVideo.current) return
    if (!srcObject) return
    refVideo.current?.load()
    refVideo.current.srcObject = srcObject
    const playPromise = refVideo.current?.play()
    if (playPromise !== undefined) {
      playPromise
      .then((_) => {
              // console.log('h1 SUCCESS srcObject : ', srcObject)
      }).catch((error) => {
          // Auto-play was prevented
          // Show paused UI.
          console.error('h1 ERROR srcObject : ', srcObject, ' ', error)
        })
        refVideo.current.readyState
    }
  }, [srcObject])

  const show_person_icon = !srcObject || streamPaused === true;

  return (
    <div className="w-full h-full relative flex justify-center items-center">
      {show_person_icon === true && (
        <div className="absolute h-full w-full p-3 flex items-center justify-center bg-[#676F75] rounded-xl">
          {
            userName ? (
              <UserAvatar
                name={userName}
                className="h-[126px] w-[123px] rounded-full bg-white text-3xl font-bold"
              />
            )
            : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-person w-full h-full"
                viewBox="0 0 16 16"
              >
                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
              </svg>
            )
          }
        </div>
      )}
      <video
        className="h-full w-full"
        style={{
          border: '2px solid grey',
          margin: '2px',
        }}
        ref={refVideo}
        {...props}
        onClick={() => {
          const playPromise = refVideo.current?.play()
          if (playPromise !== undefined) {
            playPromise
              .then((_) => {
                // Automatic playback started!
                // Show playing UI.
                // console.log('v1 B SUCCESS srcObject : ', srcObject)
                // refVideo.current.pause();
                // refVideo.current.currentTime = 0;
              })
              .catch((error) => {
                // Auto-play was prevented
                // Show paused UI.
                // console.log(error)
                // console.log('v1 B ERROR srcObject : ', srcObject)
              })
          }
        }}
      />
    </div>
  )
}
