import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

interface DoughnutProps {
  SpecialistCount: number | string
  GuardianCount: number | string
  SpecialistData: number
  GuardianData: number
  datas: number[]
}

ChartJS.register(ArcElement, LinearScale, Tooltip, Legend)

export const DoughnutPieChart = ({
  SpecialistCount,
  GuardianCount,
  SpecialistData,
  GuardianData,
}: DoughnutProps) => {
  let SpecialistDataBalance = 100 - SpecialistData
  let GuardianDataBalance = 100 - GuardianData
  const data = {
    datasets: [
      {
        data: [SpecialistData, SpecialistDataBalance],
        backgroundColor: ['#FF993B', '#F8E3DC'],
        borderWidth: 1,
        cutout: '70%',
        borderRadius: 10,
      },
      {
        data: [GuardianData, GuardianDataBalance],
        backgroundColor: ['#00C3F9', '#CFE0F7'],
        borderWidth: 1,
        cutout: '70%',
        borderRadius: 10,
      },
    ],
  }

  const config = {
    type: 'doughnut',
    data,
    options: {
      plugins: {
        legend: {
          display: true,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  }

  return (
    <div className="box-border bg-white   shadow-md rounded-2xl pb-4 h-full">
      <p className="font-display text-[#26323C] text-xl  font-normal pb-3 pt-5 pl-5">
        Conference Call
      </p>
      <hr />

      <div className="flex flex-row gap-4">
        <div className="h-[50%] w-[50%]  pl-3 ">
          <Doughnut data={data} />
        </div>
        <section className="pt-8">
          <div className="flex flex-col items-center ">
            <div className="flex items-center gap-4">
              <span className=" bg-[#FF993B] w-3 h-3 rounded-full"></span>
              <span className="font-display not-italic text-gray-400 text-xl">
                Specialist
              </span>
            </div>
            <p className="font-display not-italic text-[#26323C] text-xl pb-4">
              {SpecialistCount}
            </p>
          </div>
          <div className="flex flex-col items-center ">
            <div className="flex items-center gap-4">
              <span className=" bg-[#00C3F9] w-3 h-3 rounded-full"></span>
              <span className="font-display not-italic text-gray-400 text-xl">
                Guardian
              </span>
            </div>
            <p className="font-display not-italic text-[#26323C] text-xl pb-4">
              {SpecialistCount}
            </p>
          </div>
        </section>
      </div>
    </div>
  )
}
