import {
  HeartPulse,
  Oxygen,
  PR,
  RR,
  Sphygmomanometer,
  Temperature,
} from '../assets/icons'
import { BG, Hb, TC, UA } from '../assets/icons'

export interface LiveVitalsDataType {
  icon: JSX.Element
  value: string
  content: string
}
export interface BasicBloodTestDataType {
  icon: JSX.Element
  value: string
  content: string
}
export const LiveVitalsData = [
  {
    key: 1,
    icon: <Sphygmomanometer />,
    value: '126/98',
    content: 'mmHg',
  },
  {
    key: 2,
    icon: <HeartPulse />,
    value: '126/98',
    content: 'mmHg',
  },
  {
    key: 3,
    icon: <Temperature />,
    value: '126/98',
    content: 'mmHg',
  },
  {
    key: 4,
    icon: <Oxygen />,
    value: '126/98',
    content: 'mmHg',
  },
  {
    key: 5,
    icon: <RR />,
    value: '126/98',
    content: 'mmHg',
  },
  {
    key: 6,
    icon: <PR />,
    value: '126/98',
    content: 'mmHg',
  },
]

// export const BasicBloodTestData = [
//   {
//     key: 1,
//     icon: <BG />,
//     value: '149',
//     content: 'mgl/mL',
//   },
//   {
//     key: 2,
//     icon: <Hb />,
//     value: '149',
//     content: 'gl/dl',
//   },
//   {
//     key: 3,
//     icon: <UA />,
//     value: '149',
//     content: 'mgl/mL',
//   },
//   {
//     key: 4,
//     icon: <TC />,
//     value: '149',
//     content: 'mg/dl',
//   },
// ]
