import { useState } from 'react'
import { CloseIcon, HamburgerIcon, SendIcon } from '../../../assets/icons'
import { useWindowResize } from '../../../hook/window'
import {
  ChatScreen,
  Video,
  AudioComponent,
  MenuOverlay,
} from '../../Components'

const SplitScreen = ({
  chatMessages,
  message,
  onTypeMessage,
  isSendingMessage,
  sendMessage,
  handleLayout,
  currentUserVideo,
  Hamburger,
  closeChat,
  chatButton,
  dataSource,
  isMediaTrackAvailable,
}: any) => {
  const userType = ['PATIENT', 'GUARDIAN']
  const userVideo = [dataSource?.currentMedia, ...dataSource.otherUsers]
  const [width] = useWindowResize()
  const [shouldShowMenuOverlay, setShouldShowMenuOverlay] =
    useState<boolean>(true)

  const handleMenuOverlay = () => {
    setShouldShowMenuOverlay(!shouldShowMenuOverlay)
    handleLayout(!shouldShowMenuOverlay, 'splitScreen')
  }
  const handleHamburgerIcon = () => {
    Hamburger(shouldShowMenuOverlay)
    setShouldShowMenuOverlay(!shouldShowMenuOverlay)
  }
  const handleCloseChat = () => {
    closeChat({ name: 'chat' })
  }
  function chatMessagesAsList() {
    let messages = []
    for (const messageKey in chatMessages) {
      messages.push(chatMessages[messageKey])
    }
    return messages
  }
  return (
    <>
      <section
        className={`relative flex w-full h-full justify-center gap-1 md:gap-0 lg:gap-0 md:justify-evenly 
         lg:justify-evenly xl:justify-evenly items-center md:pt-6 lg:pt-6 ${
           dataSource.otherUsers.length <= 1 ? ' flex-col ' : ' flex-wrap '
         }`}
      >
        {/* {otherUserVideo} */}
        {shouldShowMenuOverlay ? (
          userVideo?.map((userVideoData: any, index: any) => {
            const muteAudio =
              userVideoData.medias?.audio?.paused === true ? true : false

            const videoSourceObject =
              index === 0
                ? userVideoData.current?.mediaStream
                : userVideoData.medias?.video?.stream

            const streamPaused =
              index === 0
                ? !isMediaTrackAvailable?.video?.action
                : userVideoData.medias?.video?.paused

            const audioSourceObject = userVideoData.medias?.audio?.stream
            return (
              <div
                className={`relative  
            ${
              dataSource.otherUsers.length <= 1
                ? 'w-[80%] h-[48%]'
                : 'w-[48%] h-[48%] '
            }`}
              >
                {!userType.includes(userVideo[0]?.userType) &&
                  userVideo[index]?.userType === 'PATIENT' && (
                    <button
                      className="absolute left-2 top-[10px] z-10 "
                      onClick={() =>
                        width > 768
                          ? handleMenuOverlay()
                          : handleHamburgerIcon()
                      }
                    >
                      <HamburgerIcon className="mix-blend-multiply h-[30px] w-[30px] z-10" />
                    </button>
                  )}
                <Video
                  className="object-cover h-full w-full  rounded-xl "
                  muted={true}
                  srcObject={videoSourceObject}
                  streamPaused={streamPaused}
                  userName={userVideoData?.userName}
                />
                <AudioComponent
                  srcObject={audioSourceObject}
                  muted={muteAudio}
                />
                <p
                  className="text-xs md:text-base lg:text-base font-medium md:font-bold lg:font-bold text-white absolute bottom-3 left-2 
              opacity-[0.75] truncate md:max-w-[172px] max-w-[115px]"
                >
                  {userVideoData?.userType} : {userVideoData?.userName}
                </p>
              </div>
            )
          })
        ) : (
          <MenuOverlay closeMenuOverLay={handleMenuOverlay} />
        )}
        {/* {otherUserVideo} */}
        {/* {Chat Box } */}
        {width <= 768 && chatButton && (
          <section
            className={` absolute  ${
              width >= 768
                ? ' right-6 top-6 h-[93%] w-[35%] ease-in duration-300'
                : 'h-full w-[97%]'
            }`}
          >
            <div
              className="bg-[rgba(38,50,60,0.80)] w-full h-full rounded-lg border-2 flex flex-col 
              justify-between p-5"
            >
              <button
                className="flex justify-end pr-1"
                onClick={() => handleCloseChat()}
              >
                <CloseIcon className="h-5 w-5" fill="white" />
              </button>
              <section className="flex-1 overflow-y-auto ">
                {chatMessagesAsList().map((message: any) => {
                  return (
                    <>
                      <ChatScreen message={message} key={message.messageId} />
                    </>
                  )
                })}
              </section>
              <>
                <div className="justify-self-end flex justify-between items-center gap-1">
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight 
                  focus:outline-none focus:shadow-outline"
                    type="text"
                    placeholder="your message here"
                    value={message}
                    onChange={(event) => onTypeMessage(event.target.value)}
                  />

                  {isSendingMessage === true ? (
                    <div className="send-message-loader absolute top-2 right-2 cursor-pointer" />
                  ) : (
                    <button onClick={sendMessage}>
                      <SendIcon
                        fill="white"
                        className="absolute top-1 right-1 cursor-pointer hover:scale-110 "
                      />
                    </button>
                  )}
                </div>
              </>
            </div>
          </section>
        )}
        {/* {Chat Box } */}
      </section>
    </>
  )
}

export default SplitScreen
