import { useState } from 'react'
import { SymptomsData } from '../../../constants/Symptoms'
import { useWindowResize } from '../../../hook/window'

const Symptoms = () => {
  const [width] = useWindowResize()
  const [isExpandedId, setIsExpandedID] = useState()
  const toggleExpand = (id: any) => {
    setIsExpandedID(id)
  }

  return (
    <section className="mt-5 p-5">
      {SymptomsData?.map((symptoms: any) => {
        const { title, description, key } = symptoms

        return (
          <div className="flex justify-center items-center mb-[30px] gap-3">
            {symptoms.symptomsImages?.map((index: any) => (
              <div className="flex flex-col justify-center items-center ">
                <img src={index.image} alt="image" />
                <span className="text-[#000000] text-xs font-bold">
                  {index.name}
                </span>
              </div>
            ))}
            <div
              key={key}
              className="flex flex-col bg-[#779dbd14] py-4 px-4 rounded-xl"
            >
              <span className="text-[#000000] text-xs font-bold mb-1">
                {title}
              </span>
              {isExpandedId === key ||
              (width >= 475 && description.length > 100) ? (
                <span className="text-[#868585] text-xs font-normal">
                  {description}
                </span>
              ) : (
                <>
                  <span className="text-[#868585] text-xs font-normal">
                    {description.slice(0, 100)}...
                  </span>
                </>
              )}
              {width <= 475 && (
                <button
                  onClick={() =>
                    isExpandedId ? toggleExpand('') : toggleExpand(key)
                  }
                  className="flex justify-end text-[#3767B1] text-xs font-bold"
                >
                  {isExpandedId === key ? ' Read less...' : ' Read more...'}
                </button>
              )}
            </div>
          </div>
        )
      })}
    </section>
  )
}

export default Symptoms
