import { useEffect, useState } from 'react'
import AudioComponent from '../../components/Audio'
import CONDITIONAL_COMP from '../../components/Conditional'
import useCall from './useCall'
import Video from '../../components/Video'
import './call.css'
import { stateType } from '../../types'
import useQuery from '../../hook/query'
import { generateRandomUUID, getRandomTwoDigitNumber } from './utils'
import { MessageType, UrlParamsDataType, UserTypesType } from './types'

function CallExample() {
  // const [userName, setUserName] = useState<string>("user");
  const [user, setUser]: stateType<UrlParamsDataType> =
    useState<UrlParamsDataType>({
      userId: '',
      userName: '',
      userType: 'DOCTOR',
      callId: null,
    })
  const {
    leaveCall,
    joinOrCreateCall,
    toggleVideo,
    toggleAudio,
    videoCallMetaData,
    isMediaTrackAvailable,
    onSocketDisconnect,
    otherUsers,
    mediaTrack,
    toggleOtherUserAudio,
    chatMessages,
    message,
    onTypeMessage,
    isSendingMessage,
    sendMessage,
    isCallConnecting,
  } = useCall()

  const query = useQuery()

  useEffect(() => {
    // url 1 : http://localhost:4000/call-example?user-id=123456&user-type=DOCTOR&user-name=Shanthi
    // url 2 : http://localhost:4000/call-example?user-id=78910&user-type=PATIENT&user-name=Gopal&call-id=
    if (!query) return
    let _user: UrlParamsDataType = {
      userId: '',
      userName: '',
      userType: 'DOCTOR',
      callId: null,
    }
    if (query.get('user-id')) {
      _user.userId = query.get('user-id')
        ? (query.get('user-id') as string)
        : generateRandomUUID()
    }
    if (query.get('user-type')) {
      _user.userType = query.get('user-type')
        ? (query.get('user-type') as UserTypesType)
        : 'DOCTOR'
    }
    if (query.get('user-name')) {
      _user.userName = query.get('user-name')
        ? (query.get('user-name') as string)
        : `user-${getRandomTwoDigitNumber()}`
    }
    if (query.get('call-id')) {
      _user.callId = query.get('call-id')
        ? (query.get('call-id') as string)
        : null
    }
    setUser(_user)
  }, [query])

  useEffect(() => {
    if (user && user.userName && user.userId && user.userType) {
      joinOrCreateCall({
        userType: user.userType,
        userId: user.userId,
        userName: user.userName,
        callId: user.callId,
      })
    }
  }, [user])

  // useEffect(() => {
  //   const currentMediaTrack = {
  //     ...mediaTrack,
  //     userName: user.userName,
  //     userId: user.userId,
  //     userType: user.userType,
  //     callId: user.callId,
  //   }
  //   const userStreamData = {
  //     currentMedia: currentMediaTrack,
  //     otherUsers: otherUsers || [],
  //   }
  //   console.log('otherUsers', userStreamData)
  // }, [user, otherUsers, mediaTrack, isMediaTrackAvailable, videoCallMetaData])

  onSocketDisconnect(() => {
    // setUserName('user')
  })

  function CameraToggleButton() {
    const camera_video_on = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-camera-video h-7 w-7"
        viewBox="0 0 16 16"
      >
        <path
          fill="#ffff"
          fillRule="evenodd"
          d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"
        />
      </svg>
    )

    const camera_video_off = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-camera-video-off h-7 w-7"
        viewBox="0 0 16 16"
      >
        <path
          fill="#ffff"
          fillRule="evenodd"
          d="M10.961 12.365a1.99 1.99 0 0 0 .522-1.103l3.11 1.382A1 1 0 0 0 16 11.731V4.269a1 1 0 0 0-1.406-.913l-3.111 1.382A2 2 0 0 0 9.5 3H4.272l.714 1H9.5a1 1 0 0 1 1 1v6a1 1 0 0 1-.144.518l.605.847zM1.428 4.18A.999.999 0 0 0 1 5v6a1 1 0 0 0 1 1h5.014l.714 1H2a2 2 0 0 1-2-2V5c0-.675.334-1.272.847-1.634l.58.814zM15 11.73l-3.5-1.555v-4.35L15 4.269v7.462zm-4.407 3.56-10-14 .814-.58 10 14-.814.58z"
        />
      </svg>
    )

    if (isMediaTrackAvailable.video.action) {
      return camera_video_off
    } else {
      return camera_video_on
    }
  }

  function MicToggleButton() {
    const mic_on = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-mic h-7 w-7"
        viewBox="0 0 16 16"
      >
        <path
          fill="#ffff"
          d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"
        />
        <path
          fill="#ffff"
          d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z"
        />
      </svg>
    )

    const mic_mute = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-mic-mute h-7 w-7"
        viewBox="0 0 16 16"
      >
        <path
          fill="#ffff"
          d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4.02 4.02 0 0 0 12 8V7a.5.5 0 0 1 1 0v1zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a4.973 4.973 0 0 1-2.43.923V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 1 0v1a4 4 0 0 0 4 4zm3-9v4.879l-1-1V3a2 2 0 0 0-3.997-.118l-.845-.845A3.001 3.001 0 0 1 11 3z"
        />
        <path
          fill="#ffff"
          d="m9.486 10.607-.748-.748A2 2 0 0 1 6 8v-.878l-1-1V8a3 3 0 0 0 4.486 2.607zm-7.84-9.253 12 12 .708-.708-12-12-.708.708z"
        />
      </svg>
    )

    if (isMediaTrackAvailable.audio.action) {
      return mic_mute
    } else {
      return mic_on
    }
  }

  function copyText() {
    navigator.clipboard.writeText(videoCallMetaData?.callId)
  }

  function SendIcon({ className = '' }: { className: string }) {
    return (
      <div className={className} onClick={sendMessage}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          version="1.1"
          id="svg822"
        >
          <defs id="defs816" />
          <g id="layer1" transform="translate(0,-289.0625)">
            <path
              style={{
                opacity: 1,
                fill: '#3B82F6',
                fillOpacity: 1,
                stroke: 'none',
                strokeWidth: 2,
                strokeMiterlimit: 4,
                strokeDasharray: 'none',
                strokeOpacity: 1,
              }}
              d="m 25.5,304.0625 c 0,-1.11962 -1,-1.5 -1,-1.5 l -20,-8 3.60352,8.56055 L 17.5,304.0625 8.10352,305.00195 4.5,313.5625 l 20,-8 c 0,0 1,-0.38038 1,-1.5 z"
              id="rect820"
            />
          </g>
        </svg>
      </div>
    )
  }

  function ChatMessage({ message }: { message: MessageType }) {
    const date = new Date(message.dateTime).toLocaleDateString()
    if (message.isYou === true) {
      return (
        <div className="flex flex-row justify-end mb-2 px-2 w-full">
          <div className="w-11/12">
            <p className="w-full text-gray-300 text-right text-xs">{date}</p>
            <div className="w-full p-1 px-2 bg-blue-500 rounded-xl">
              <span className="text-gray-300 text-sm">you</span>
              <p className="text-white">{message.data}</p>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="flex flex-row justify-start mb-2 px-2 w-full">
          <div className="w-11/12">
            <p className="w-full text-gray-300 text-left text-xs">{date}</p>
            <div className="w-full p-1 px-2 bg-slate-200 rounded-xl">
              <span className="text-gray-600 text-sm">
                {message.senderName}
              </span>
              <p>{message.data}</p>
            </div>
          </div>
        </div>
      )
    }
  }

  function chatMessagesAsList() {
    let messages = []
    for (const messageKey in chatMessages) {
      messages.push(chatMessages[messageKey])
    }
    return messages
  }

  const CONTAINS_USER_ID_CALLID_PEERID: boolean = !!(
    user.userId.trim().length > 4 &&
    videoCallMetaData?.callId.trim().length > 4 &&
    videoCallMetaData.peerId.trim().length > 4
  )
  const NOT_CONTAIN_USERNAME_CALLID_PEERID: boolean = !(
    user.userId.trim().length > 4 &&
    videoCallMetaData?.callId.trim().length > 4 &&
    videoCallMetaData.peerId.trim().length > 4
  )
  const USER_CONTAINS_CALLID_PEERID =
    videoCallMetaData.callId.trim().length > 4 &&
    videoCallMetaData.peerId.trim().length > 4
  const SHOW_CURRENT_USER_VIDEO =
    isMediaTrackAvailable.video.action === true &&
    isMediaTrackAvailable.video.device &&
    isMediaTrackAvailable.mediaStream

  return (
    <main className="w-full h-full flex flex-row justify-between">
      <CONDITIONAL_COMP show={isCallConnecting}>
        <div className="fixed inset-0 flex items-center justify-center">
          <div className="call-connecting-loader" />
        </div>
      </CONDITIONAL_COMP>
      <section className="flex-1 flex flex-col items-center">
        <CONDITIONAL_COMP show={CONTAINS_USER_ID_CALLID_PEERID}>
          <section className="shadow-md rounded-md p-3 w-fit">
            <h1 className="text-gray-700">
              <strong>User Name : </strong> <span>{user.userName}</span>{' '}
            </h1>
            <div className="flex flex-row items-center justify-between">
              <h1 className="text-gray-700 mr-4">
                <strong>Call Id : </strong>
                <span>{videoCallMetaData.callId}</span>
              </h1>
              <svg
                onClick={copyText}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-clipboard cursor-pointer "
                viewBox="0 0 16 16"
              >
                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
              </svg>
            </div>
          </section>
        </CONDITIONAL_COMP>

        <section className="w-f flex flex-wrap">
          <CONDITIONAL_COMP show={USER_CONTAINS_CALLID_PEERID}>
            <div className="shadow-md rounded-md p-3 m-3 h-80 w-80 bg-white border border-green-500 flex flex-col gap-3 ">
              <section className="grow bg-gray-300 rounded-md flex justify-center items-center">
                <Video
                  muted={true}
                  srcObject={
                    SHOW_CURRENT_USER_VIDEO
                      ? mediaTrack.current?.mediaStream
                      : null
                  }
                  streamPaused={false}
                />
              </section>
              <section className="shadow-md rounded-md p-3 w-full">
                <h1 className="text-gray-700">
                  <strong>User Name : </strong> <span>{user.userName}</span>{' '}
                </h1>
                <h1 className="text-gray-700">
                  <strong>Peer Id : </strong>{' '}
                  <span>{videoCallMetaData.peerId}</span>{' '}
                </h1>
              </section>
            </div>
          </CONDITIONAL_COMP>

          {otherUsers &&
            otherUsers.map((otherUser) => {
              const OTHER_USER_HAS_VIDEO_STREAM_AND = otherUser.medias?.video
                ?.stream
                ? true
                : false
              const OTHER_USER_HAS_AUDIO_STREAM = otherUser.medias?.audio
                ?.stream
                ? true
                : false
              const OTHER_USER_HAS_VIDEO_STREAM_AND_NOT_PAUSED =
                otherUser.medias?.video?.stream &&
                otherUser.medias?.video.paused === false
                  ? true
                  : false
              const OTHER_USER_HAS_AUDIO_STREAM_AND_NOT_PAUSED =
                otherUser.medias?.audio?.stream &&
                otherUser.medias?.audio?.paused === false
                  ? true
                  : false

              return (
                <div
                  key={otherUser.producerPeerId}
                  className="shadow-md rounded-md p-3 m-3 h-80 w-80 bg-white border flex flex-col gap-3 "
                >
                  <section className="grow bg-gray-300 rounded-md flex justify-center items-center">
                    <Video
                      srcObject={
                        OTHER_USER_HAS_VIDEO_STREAM_AND
                          ? otherUser.medias?.video?.stream
                          : null
                      }
                      streamPaused={false}
                    />
                    <CONDITIONAL_COMP show={OTHER_USER_HAS_AUDIO_STREAM}>
                      <AudioComponent
                        srcObject={otherUser.medias?.audio?.stream}
                        muted={
                          otherUser.medias?.audio?.paused === true
                            ? true
                            : false
                        }
                      />
                    </CONDITIONAL_COMP>
                  </section>
                  <section className="shadow-md rounded-md p-3 w-full">
                    <h1 className="text-gray-700">
                      <strong>User Name : </strong>{' '}
                      <span>{otherUser.userName}</span>{' '}
                    </h1>
                    <h1 className="text-gray-700">
                      <strong>User Type : </strong>{' '}
                      <span>{otherUser.userType}</span>{' '}
                    </h1>
                    <h1 className="text-gray-700 mb-2">
                      <strong>Peer Id : </strong>{' '}
                      <span>{otherUser.producerPeerId}</span>
                    </h1>
                    <span
                      className={`${
                        OTHER_USER_HAS_VIDEO_STREAM_AND_NOT_PAUSED
                          ? 'text-white bg-green-500'
                          : 'text-gray-400 border border-gray-300'
                      }  ml-2 p-1 px-2 rounded-md`}
                    >
                      Video
                    </span>
                    <span
                      className={`${
                        OTHER_USER_HAS_AUDIO_STREAM_AND_NOT_PAUSED
                          ? 'text-white bg-green-500'
                          : 'text-gray-400 border border-gray-300'
                      }  ml-2 p-1 px-2 rounded-md`}
                      onClick={() =>
                        toggleOtherUserAudio({
                          peerId: `${otherUser.producerPeerId}`,
                          mute: OTHER_USER_HAS_AUDIO_STREAM_AND_NOT_PAUSED,
                        })
                      }
                    >
                      Audio
                    </span>
                  </section>
                </div>
              )
            })}
        </section>

        <CONDITIONAL_COMP show={USER_CONTAINS_CALLID_PEERID}>
          <section className="fixed bottom-1">
            <button
              className={`${
                isMediaTrackAvailable?.audio.action
                  ? 'bg-green-500'
                  : 'bg-red-500'
              }  shadow-lg rounded-full p-3 m-3`}
              onClick={toggleAudio}
            >
              <MicToggleButton />
            </button>
            <button
              className={`${
                isMediaTrackAvailable?.video.action
                  ? 'bg-green-500'
                  : 'bg-red-500'
              } shadow-lg rounded-full p-3 m-3`}
              onClick={toggleVideo}
            >
              <CameraToggleButton />
            </button>
            <button
              className={`bg-red-500 shadow-lg rounded-full p-3 m-3`}
              onClick={leaveCall}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-telephone-x h-7 w-7"
                viewBox="0 0 16 16"
              >
                <path
                  fill="#ffff"
                  d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                />
                <path
                  fill="#ffff"
                  fillRule="evenodd"
                  d="M11.146 1.646a.5.5 0 0 1 .708 0L13 2.793l1.146-1.147a.5.5 0 0 1 .708.708L13.707 3.5l1.147 1.146a.5.5 0 0 1-.708.708L13 4.207l-1.146 1.147a.5.5 0 0 1-.708-.708L12.293 3.5l-1.147-1.146a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </button>
          </section>
        </CONDITIONAL_COMP>
      </section>

      <CONDITIONAL_COMP show={CONTAINS_USER_ID_CALLID_PEERID}>
        <section className="h-full w-3/12 p-3">
          <div className="h-full w-full shadow-2xl rounded-2xl flex flex-col">
            <section className="flex-1 overflow-y-auto ">
              {chatMessagesAsList().map((message) => {
                return <ChatMessage message={message} key={message.messageId} />
              })}
            </section>
            <section className="">
              <div className="relative">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="your message here"
                  value={message}
                  onChange={(event) => onTypeMessage(event.target.value)}
                />
                {isSendingMessage === true ? (
                  <div className="send-message-loader absolute top-2 right-2" />
                ) : (
                  <SendIcon className="absolute top-1 right-1 cursor-pointer hover:scale-110" />
                )}
              </div>
            </section>
          </div>
        </section>
      </CONDITIONAL_COMP>
    </main>
  )
}

export default CallExample
