import { Tabs } from '..'
import { StethescopeComponentTabData } from '../../StethescopeTabData'
import { useWindowResize } from '../../../hook/window'

const StethescopeComponent = () => {
  const [width] = useWindowResize()
  return (
    <div className="flex">
      {width >= 475 ? (
        <div className="w-full flex ">
          <Tabs row={false} tabData={StethescopeComponentTabData} />
        </div>
      ) : (
        <div className="w-full h-full mt-3 ">
          <Tabs row={true} tabData={StethescopeComponentTabData} />
        </div>
      )}
    </div>
  )
}

export default StethescopeComponent
