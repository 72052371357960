import { Bell, Question, Search } from '../../assets/icons'
import UserAvatar from '../UserAvatar'

interface Props {
  adminName: string
  staff: string
}

const AppBar = ({ adminName, staff }: Props) => {
  return (
    <div className="box-border border-slate-300 bg-white w-full  ">
      <div className="flex flex-row  items-center">
        <div className="flex flex-row grow py-4 items-center  ">
          <span className="pl-10 pr-6 ">
            <Search />
          </span>
          <input
            className="focus:outline-none grow"
            placeholder="Search..."
          ></input>
        </div>
        <div className="flex flex-row ">
          <span className="w-px h-6 bg-[#94a3b8]"></span>
        </div>
        <div className="flex flex-row items-center pr-14">
          <image className=" rounded-full pl-8 pr-4">
            <UserAvatar />
          </image>
          <div className="flex flex-col   ">
            <p className=" font-display text-2xl font-bold ">{adminName}</p>
            <p className="font-display text-slate-500">{staff}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppBar
