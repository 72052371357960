interface Props {
  icon: any
  value: string
  content: string
  className?: string
}

const VitalCard = ({ icon, value, content, className }: Props) => {
  return (
    <div
      className={`bg-white w-[129.22px] h-[78.016px] rounded-2xl border-[1px] border-[rgba(85,202,240,0.40)] ${className}`}
    >
      <div className="flex flex-row justify-between pt-2.5 p-2">
        {icon}
        <p className="font-display md:text-xl lg:text-xl text-base  text-end font-bold text-[#26323C] opacity-75 ">
          {value} <p>{content}</p>
        </p>
      </div>
    </div>
  )
}
export default VitalCard
