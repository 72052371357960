import { accessRules } from '../../constants/abilityConstants'
import { UserTypesType } from '../../pages/Call/types'

type AccessIconProps = {
  children: JSX.Element | JSX.Element[]
  role: UserTypesType
  action: 'remove' | 'mute' | 'pin' | 'hamburger'
  userType: UserTypesType
}
const AccessIcon = ({ children, role, action, userType }: AccessIconProps) => {
  const currentRole = accessRules[role]
  if (currentRole === undefined) {
    return null
  }
  return (
    <>{accessRules?.[role]?.[action]?.includes(userType) ? children : null}</>
  )
}
export default AccessIcon
