type HealthReportProps = {
  HealthCount: number
  PrescriptionCount: number
}

export const HealthReport = ({
  HealthCount,
  PrescriptionCount,
}: HealthReportProps) => {
  return (
    <div className="box-border bg-white w-full shadow-md rounded-2xl h-full">
      <p className="font-display text-lg not-italic font-normal text-[#26323C] pt-4 pb-4 ps-10">
        Health Reports & Prescription
      </p>
      <hr />
      <div className="flex flex-row gap-6 px-6 py-5  ">
        <div className="box-border border-slate-300 border-[1px] w-1/2  bg-[#f1f5f9] rounded-2xl  ">
          <div className="text-center pt-5">
            <div className="font-display text-lg font-bold ">{HealthCount}</div>
            <p className="font-display text-base not-italic font-normal text-gray-500">
              HEALTH
            </p>
          </div>
        </div>
        <div className="box-border border-slate-300 border-[1px] w-1/2 bg-[#f1f5f9] rounded-2xl ">
          <div className="text-center pt-5">
            <div className="font-display text-lg  font-bold">
              {PrescriptionCount}
            </div>
            <p className="font-display text-base not-italic font-normal text-gray-500">
              PRESCRIPTION
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
