import { MedicalImagesData } from '../../../constants/MedicalImages'
import { useWindowResize } from '../../../hook/window'
import MedicalImageCard from '../../Cards/MedicalImageCard'

const MedicalImages = () => {
  const [width] = useWindowResize()
  return (
    <div className="flex flex-col p-5">
      {MedicalImagesData?.map((medicalImages: any) => {
        return (
          <>
            <span className="text-black lg:text-[#59636C] md:text-[#59636C] text-xs md:text-sm lg:text-base font-bold ">
              {medicalImages?.title}
            </span>
            <div
              className={`grid md:grid-flow-col lg:grid-flow-col xl:grid-flow-col justify-center items-center gap-7 ${
                width <= 375 ? ' flex-col ' : ' flex-row  '
              }`}

              // className="flex justify-center items-center gap-7"
            >
              {medicalImages.images.map((imageData: any) => (
                <MedicalImageCard
                  source={imageData.image}
                  name={imageData.name}
                />
              ))}
            </div>
          </>
        )
      })}
    </div>
  )
}

export default MedicalImages
