import { VideoHTMLAttributes, useEffect, useRef } from 'react'

type PropsType = VideoHTMLAttributes<HTMLAudioElement> & {
  srcObject: MediaStream | undefined | null
  muted: boolean
}

export default function AudioComponent({
  srcObject,
  muted,
  ...props
}: PropsType) {
  const refVideo = useRef<HTMLAudioElement>(null)

  useEffect(() => {
    if (refVideo.current) {
      try {
        refVideo.current.setSinkId('default')
      }
      catch (error) {
        console.log("Skind is not available")
      }
    }
  }, [])

  useEffect(() => {
    if (!refVideo.current) return
    if (!srcObject) return
    refVideo.current?.load()
    refVideo.current.srcObject = srcObject
    const playPromise = refVideo.current?.play()
    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          // Automatic playback started!
          // Show playing UI.
          console.log("A3 ####### Audio Playing :: ", srcObject);
          // refVideo.current.pause();
          // refVideo.current.currentTime = 0;
        })
        .catch((error) => {
          // Auto-play was prevented
          // Show paused UI.
          console.error("A3 ####### Audio Error ##### ", error);
        })
    }
  }, [srcObject])

  return <audio ref={refVideo} muted={muted} {...props} />
}
