import {
  AbdominalHeartbeatImage,
  HeartbeatImage,
  LungsAnteriorImage,
  LungsPosteriorImage,
  PregnantHeartbeatImage,
} from '../../assets/images'
import AudioRecordCard from '../AudioRecordCard'
import StethescopeImageCard from '../StethescopeImageCard'

export const StethescopeComponentTabData = [
  {
    key: 1,
    label: 'HaertBeat',
    content: (
      <div className="flex lg:flex-row md:gap-6 md:items-center md:justify-center mt-[30px] md:mt-0 lg:mt-0">
        <StethescopeImageCard image={HeartbeatImage} />
        <div className="flex flex-col md:gap-6 !gap-2">
          <AudioRecordCard />
          <AudioRecordCard />
          <AudioRecordCard />
          <AudioRecordCard />
        </div>
      </div>
    ),
  },
  {
    key: 2,
    label: 'Lungs',
    content: (
      <section>
        <div className="flex lg:flex-row  md:p-5 gap-6 md:items-center md:justify-center mt-[30px] md:mt-0 lg:mt-0">
          <StethescopeImageCard image={LungsAnteriorImage} />
          <div className="flex flex-col md:gap-6 !gap-2">
            <AudioRecordCard />
            <AudioRecordCard />
            <AudioRecordCard />
          </div>
        </div>
        <div className="flex lg:flex-row  md:p-5 gap-6 md:items-center md:justify-center mt-[30px] md:mt-0 lg:mt-0">
          <StethescopeImageCard image={LungsPosteriorImage} />
          <div className="flex flex-col md:gap-6 !gap-2">
            <AudioRecordCard />
            <AudioRecordCard />
            <AudioRecordCard />
            <AudioRecordCard />
          </div>
        </div>
      </section>
    ),
  },
  {
    key: 3,
    label: 'Abdominal',
    content: (
      <div className="flex lg:flex-row md:flex-col md:p-5 gap-6 md:items-center md:justify-center mt-[30px] md:mt-0 lg:mt-0">
        <StethescopeImageCard image={AbdominalHeartbeatImage} />
        <div className="flex flex-col md:gap-6 !gap-2">
          <AudioRecordCard />
          <AudioRecordCard />
          <AudioRecordCard />
          <AudioRecordCard />
        </div>
      </div>
    ),
  },
  {
    key: 4,
    label: 'Pregnant',
    content: (
      <div className="flex lg:flex-row md:flex-col md:p-5 md:gap-6 md:items-center md:justify-center mt-[30px] md:mt-0 lg:mt-0">
        <StethescopeImageCard image={PregnantHeartbeatImage} />
        <div className="flex flex-col md:gap-6 !gap-2">
          <AudioRecordCard />
          <AudioRecordCard />
          <AudioRecordCard />
        </div>
      </div>
    ),
  },
]
