import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import DropDown from '../../Dropdown'

interface LineChartProps {
  dataOne: number[]
  dataTwo: number[]
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
)

const LineChartCard = ({ dataOne, dataTwo }: LineChartProps) => {
  const data: any = {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'sep',
      'oct',
      'nov',
    ],
    datasets: [
      {
        lineTension: 0.4,
        backgroundColor: '#f0f9ff',
        borderWidth: 1,
        fill: '+1',
        data: dataOne,
        borderColor: '#00C3F9',
        pointStyle: false,
      },
      {
        lineTension: 0.4,
        fill: true,
        backgroundColor: '#f5f3ff',
        borderColor: '#913AFF',
        data: dataTwo,
        pointStyle: false,
      },
    ],
  }
  const options = {
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart - Multi Axis',
      },
    },
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        display: false,
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  }
  return (
    <div className="box-border border-slate-300 bg-white  h-full shadow-md rounded-2xl ">
      <div className="flex flex-row pt-5">
        <p className="font-display text-2xl font-normal text-[#26323C] pl-5">
          Traffic
        </p>
        <div className="pl-6">
          <DropDown
            options={['item1', 'item2']}
            placeholder={'state'}
            className={
              'flex items-center gap-2 h-7 justify-center rounded relative bg-[#f3f4f6] px-4'
            }
          />
        </div>
        <div className="flex flex-row  gap-x-6 pl-[10%]">
          <p>1D</p>
          <span className="h-5 w-px inline-block bg-[#838383]" />
          <p> 7D </p>
          <span className="h-5 w-px inline-block bg-[#838383]" />
          <p> 1M </p>
          <span className="h-5 w-px inline-block bg-[#838383]" />
          <p> 6M </p>
          <span className="h-5 w-px inline-block bg-[#838383]" />
          <p> 1Y </p>
        </div>
      </div>
      <div className=" px-3 h-full">
        <Line options={options} data={data} height={80} />
      </div>
    </div>
  )
}

export default LineChartCard
