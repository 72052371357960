import { Mylocation, ScreenZoom, ZoomIn, ZoomOut } from '../../../assets/icons'
import DropDown from '../../Dropdown'
import { InfoTail } from '../../Info Tail'
import { Map } from '../../Map'
import React, { useState } from 'react'
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

const infoTail = [
  {
    TDMName: 'TDMName',
    TDMNumber: 'ID00r95',
    TotalConsultation: 'TotalConsultation',
    TotalConsultationCount: 23,
    TotalDoctor: 'TotalDoctor',
    TotalDoctorCount: 45,
    TotalPatient: 'TotalPatient',
    TotalPatientCount: 16,
    Time: '15:00 min',
  },
  {
    TDMName: 'TDMName 2',
    TDMNumber: 'ID00r95',
    TotalConsultation: 'TotalConsultation',
    TotalConsultationCount: 23,
    TotalDoctor: 'TotalDoctor',
    TotalDoctorCount: 45,
    TotalPatient: 'TotalPatient',
    TotalPatientCount: 16,
    Time: '15:00 min',
  },

  {
    TDMName: 'TDMName 3',
    TDMNumber: 'ID00r95',
    TotalConsultation: 'TotalConsultation',
    TotalConsultationCount: 23,
    TotalDoctor: 'TotalDoctor',
    TotalDoctorCount: 45,
    TotalPatient: 'TotalPatient',
    TotalPatientCount: 16,
    Time: '15:00 min',
  },

  {
    TDMName: 'TDMName 4',
    TDMNumber: 'ID00r95',
    TotalConsultation: 'TotalConsultation',
    TotalConsultationCount: 23,
    TotalDoctor: 'TotalDoctor',
    TotalDoctorCount: 45,
    TotalPatient: 'TotalPatient',
    TotalPatientCount: 16,
    Time: '15:00 min',
  },

  {
    TDMName: 'TDMName 5',
    TDMNumber: 'ID00r95',
    TotalConsultation: 'TotalConsultation',
    TotalConsultationCount: 23,
    TotalDoctor: 'TotalDoctor',
    TotalDoctorCount: 45,
    TotalPatient: 'TotalPatient',
    TotalPatientCount: 16,
    Time: '15:00 min',
  },
  {
    TDMName: 'TDMName 5',
    TDMNumber: 'ID00r95',
    TotalConsultation: 'TotalConsultation',
    TotalConsultationCount: 23,
    TotalDoctor: 'TotalDoctor',
    TotalDoctorCount: 45,
    TotalPatient: 'TotalPatient',
    TotalPatientCount: 16,
    Time: '15:00 min',
  },
  {
    TDMName: 'TDMName 5',
    TDMNumber: 'ID00r95',
    TotalConsultation: 'TotalConsultation',
    TotalConsultationCount: 23,
    TotalDoctor: 'TotalDoctor',
    TotalDoctorCount: 45,
    TotalPatient: 'TotalPatient',
    TotalPatientCount: 16,
    Time: '15:00 min',
  },
  {
    TDMName: 'TDMName 5',
    TDMNumber: 'ID00r95',
    TotalConsultation: 'TotalConsultation',
    TotalConsultationCount: 23,
    TotalDoctor: 'TotalDoctor',
    TotalDoctorCount: 45,
    TotalPatient: 'TotalPatient',
    TotalPatientCount: 16,
    Time: '15:00 min',
  },
  {
    TDMName: 'TDMName 5',
    TDMNumber: 'ID00r95',
    TotalConsultation: 'TotalConsultation',
    TotalConsultationCount: 23,
    TotalDoctor: 'TotalDoctor',
    TotalDoctorCount: 45,
    TotalPatient: 'TotalPatient',
    TotalPatientCount: 16,
    Time: '15:00 min',
  },

  {
    TDMName: 'TDMName 5',
    TDMNumber: 'ID00r95',
    TotalConsultation: 'TotalConsultation',
    TotalConsultationCount: 23,
    TotalDoctor: 'TotalDoctor',
    TotalDoctorCount: 45,
    TotalPatient: 'TotalPatient',
    TotalPatientCount: 16,
    Time: '15:00 min',
  },
  {
    TDMName: 'TDMName 5',
    TDMNumber: 'ID00r95',
    TotalConsultation: 'TotalConsultation',
    TotalConsultationCount: 23,
    TotalDoctor: 'TotalDoctor',
    TotalDoctorCount: 45,
    TotalPatient: 'TotalPatient',
    TotalPatientCount: 16,
    Time: '15:00 min',
  },
  {
    TDMName: 'TDMName 5',
    TDMNumber: 'ID00r95',
    TotalConsultation: 'TotalConsultation',
    TotalConsultationCount: 23,
    TotalDoctor: 'TotalDoctor',
    TotalDoctorCount: 45,
    TotalPatient: 'TotalPatient',
    TotalPatientCount: 16,
    Time: '15:00 min',
  },
  {
    TDMName: 'TDMName 5',
    TDMNumber: 'ID00r95',
    TotalConsultation: 'TotalConsultation',
    TotalConsultationCount: 23,
    TotalDoctor: 'TotalDoctor',
    TotalDoctorCount: 45,
    TotalPatient: 'TotalPatient',
    TotalPatientCount: 16,
    Time: '15:00 min',
  },
]

export const TdmsInfoCard = ({ SeeAll }: any) => {
  const [show, setShow] = useState(true)
  const handleClick = () => {
    setShow((current) => !current)
  }

  return (
    <div className="box-border border-slate-300 bg-white w-full shadow-md rounded-2xl grow">
      <section className=" flex flex-row pt-5 pl-5   ">
        <header className="grid  grid-cols-[100px_minmax(100px,_1fr)_800px]">
          <p className="font-display text-base font-bold grow">Top TDMS</p>

          <div className="">
            <DropDown
              options={['item1', 'item2']}
              placeholder={'Filter'}
              className={
                'flex items-center gap-2 h-7 justify-center rounded relative bg-[#f3f4f6] px-4 '
              }
            />
          </div>
          <div className=" justify-self-end">
            <DropDown
              options={['item1', 'item2']}
              placeholder={'Last 7 days'}
              className={
                'flex items-center gap-2 h-7 w-36  justify-center rounded relative bg-[#f3f4f6] px-4'
              }
            />
          </div>
        </header>
      </section>
      <div className="flex flex-row w-full grow-0">
        <section className="grow w-[40%] pl-[1.5%] ">
          <ol className=" grid gap-3 pt-2">
            {infoTail.slice(0, 4).map((data) => {
              return (
                <InfoTail
                  key={uuidv4()}
                  TDMName={data.TDMName}
                  TDMNumber={data.TDMNumber}
                  TotalConsultation={data.TotalConsultation}
                  TotalConsultationCount={data.TotalConsultationCount}
                  TotalDoctor={data.TotalDoctor}
                  TotalDoctorCount={data.TotalDoctorCount}
                  TotalPatient={data.TotalPatient}
                  TotalPatientCount={data.TotalPatientCount}
                  Time={data.Time}
                />
              )
            })}
          </ol>
          <Link
            to="/first"
            target="_blank"
            onClick={handleClick}
            className="py-2 pl-2 font-display text-base font-bold "
          >
            See all
          </Link>
        </section>
        <section className=" pr-2 pt-2 grow ">
          <div className="w-full pl-[1.5%]">
            <Map />
          </div>
          <footer className="flex flex-row py-4 gap-[55%] pl-3 ">
            <span className="flex flex-row rounded bg-[#f3f4f6] gap-1  pl-2 pt-2 ">
              <div className="pr-5">
                <Mylocation className=" w-8" />
              </div>
              <ZoomIn className="w-8" />
              <div className="pt-1">
                <span className="w-px h-5 bg-black"></span>
              </div>
              <ZoomOut className="w-8" />
            </span>

            <span className="rounded bg-[#f3f4f6]  py-2 px-2">
              <ScreenZoom className="w-8 " />
            </span>
          </footer>
        </section>
      </div>
    </div>
  )
}
