interface RoundedButtonsProps {
  icons?: any
  className?: any
  handleClick?: any
  style?: any
  alwaysActive?: any
  active?: any
}

const RoundedButtons: React.FC<RoundedButtonsProps> = ({
  icons,
  className,
  handleClick,
  style = {},
  alwaysActive = false,
  active = false,
}) => {
  const _handleClick = () => {
    if (typeof handleClick === 'function') {
      handleClick()
    }
  }
  // console.log('object', active)
  return (
    <div>
      <button
        className={`rounded-full flex justify-center items-center w-[33px] h-[33px]
        md:w-[50px] md:h-[50px] lg:w-[50px] lg:h-[50px] ${
          active ? 'bg-[#d92029d9] ' : 'bg-white'
        }  ${className}  `}
        style={style}
        onClick={_handleClick}
      >
        {icons}
      </button>
    </div>
  )
}
export default RoundedButtons
