import { LoginPage } from '../pages/LoginPage'
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom'
import { NewTabInfo } from '../components/Cards/TdmsInfoCard/NewTab'
import { DashBoard } from '../pages/DashBoard'
import Call from '../pages/Call/Call'
import CallExample from '../pages/Call/CallExample'
import EndScreen from '../components/EndScreen'
import UserAlreadyExist from '../pages/CallFallback/UserAlreadyExist'
import MaxUsersReached from '../pages/CallFallback/MaxUsersReached'
import SomethingWentWrong from '../pages/CallFallback/SomethingWentWrong'

const RoutesWrapper = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/dashboard" element={<DashBoard />} />
        <Route path="/first" element={<NewTabInfo />} />
        <Route path="/call" element={<Call />} />
        <Route path="/call-example" element={<CallExample />} />
        <Route path="/call-fallback/user-already-exist" element={<UserAlreadyExist />} />
        <Route path="/call-fallback/exceeded-max-user-per-call" element={<MaxUsersReached />} />
        <Route path="/call-fallback/some-thing-went-wrong" element={<SomethingWentWrong />} />
        <Route path="/end-screen" element={<EndScreen />} />
      </Routes>
    </BrowserRouter>
  )
}

export default RoutesWrapper
