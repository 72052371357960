import { useState, useEffect } from 'react'

const TimerComponent = () => {
  const [time, setTime] = useState<any>(0)

  useEffect(() => {
    let interval = setInterval(() => {
      setTime((time: any) => time + 1000) // Update the time by 1000 milliseconds (1 second)
    }, 1000) // Set the interval to 1000 milliseconds (1 second)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div className="text-white opacity-[0.75] font-medium text-base">
      <span className="digits">
        {('0' + Math.floor((time / 1000 / 60 / 60) % 24)).slice(-2)}:
      </span>
      <span className="digits">
        {('0' + Math.floor((time / 1000 / 60) % 60)).slice(-2)}.
      </span>
      <span className="digits mili-sec">
        {('0' + Math.floor((time / 1000) % 60)).slice(-2)}
      </span>
    </div>
  )
}

export default TimerComponent
