import React, { Children, ReactElement } from 'react'

type Props = {
  show: boolean,
  children: React.ReactNode
}

function CONDITIONAL_COMP({ show, children }: Props): JSX.Element {
  if (show === true) return <>{children}</>
  return (<></>)
}

export default CONDITIONAL_COMP