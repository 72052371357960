import { cloneElement, useEffect, useState } from 'react'
import { useWindowResize } from '../../hook/window'
import { VideoButton } from '../../constants/videoButton'
import {
  RoundedButtons,
  MenuOverlay,
  TimerComponent,
} from '../../components/Components'
import {
  DefaultScreen,
  SplitScreen,
  VideoScreen,
} from '../../components/VideoCallScreens/VideoCallScreens'

const VideoLayout = ({
  chatMessages,
  message,
  onTypeMessage,
  isSendingMessage,
  sendMessage,
  handleVideoToggle,
  handleOtherUserAudio,
  dataSource,
  handleAudioToggle,
  isMediaTrackAvailable,
  currentUserVideo,
  handleLeaveCall,
  AppointmentId,
  userName,
  copyText,
  muteOtherUserAudio,
  removePeerById,
  userType,
}: any) => {
  const [width] = useWindowResize()
  const [buttonActions, setButtonActions] = useState<any>({
    splitscreen: width <= 786,
    endCall: true,
    stethoscope: false,
    chat: false,
    voice: false,
    video: false,
  })
  const [screenStatus, setScreenStatus] = useState<boolean>(true)
  const [removeHeaderFooter, setRemoveHeaderFooter] = useState<boolean>(true)
  const [defaultMenuOverLay, setDefaultMenuOverLay] = useState<boolean>(false)
  const [menuOverlayScreen, setMenuOverlayScreen] = useState(true)
  const [chatButton, setChatButton] = useState<boolean>(false)
  const [videoScreenUser, setVideoScreenUser] = useState<number>(1)
  const [backToVideoScreen, setBackToVideoScreen] = useState<any>(false)
  const [muteOtherUser, setMuteOtherUser] = useState<boolean>(false)

  useEffect(() => {
    if (width > 768) {
      setVideoScreenUser(1)
    } else {
      setVideoScreenUser(0)
    }
  }, [width])

  useEffect(() => {
    if (muteOtherUser) {
      dataSource?.otherUsers?.map((user: any) => {
        muteOtherUserAudio(user?.producerPeerId)
      })
    }
  }, [dataSource?.otherUsers, muteOtherUser])

  const screen = () => {
    switch (true) {
      case dataSource?.otherUsers?.length <= videoScreenUser:
        console.log(muteOtherUserAudio, 'm1')
        return (
          <VideoScreen
            dataSource={dataSource}
            currentUserVideo={currentUserVideo}
            handleOtherUserAudio={handleOtherUserAudio}
            chatButton={chatButton}
            chatMessages={chatMessages}
            message={message}
            onTypeMessage={onTypeMessage}
            isSendingMessage={isSendingMessage}
            sendMessage={sendMessage}
            closeChat={handleCallButton}
            handleLayout={HandleLayout}
            splitMenuOverlay={defaultMenuOverLay ? !screenStatus : screenStatus}
            currentUserType={userType}
            isMediaTrackAvailable={isMediaTrackAvailable}
          />
        )
      case !screenStatus:
      case width <= 768: {
        return (
          <SplitScreen
            chatButton={chatButton}
            chatMessages={chatMessages}
            message={message}
            onTypeMessage={onTypeMessage}
            isSendingMessage={isSendingMessage}
            sendMessage={sendMessage}
            closeChat={handleCallButton}
            handleOtherUserAudio={handleOtherUserAudio}
            handleLayout={HandleLayout}
            dataSource={dataSource}
            currentUserVideo={currentUserVideo}
            Hamburger={backToScreen}
            currentUserType={userType}
            isMediaTrackAvailable={isMediaTrackAvailable}
          />
        )
      }

      default:
        return (
          <DefaultScreen
            chatMessages={chatMessages}
            message={message}
            onTypeMessage={onTypeMessage}
            isSendingMessage={isSendingMessage}
            sendMessage={sendMessage}
            handleOtherUserAudio={handleOtherUserAudio}
            dataSource={dataSource}
            currentUserVideo={currentUserVideo}
            handleLayout={HandleLayout}
            chatButton={chatButton}
            splitMenuOverlay={defaultMenuOverLay ? !screenStatus : screenStatus}
            closeChat={handleCallButton}
            removePeerById={removePeerById}
            currentUserType={userType}
            isMediaTrackAvailable={isMediaTrackAvailable}
          />
        )
    }
  }

  const backToScreen = () => {
    setMenuOverlayScreen(!menuOverlayScreen)
  }

  const HandleLayout = (layoutData: any, layoutScreen: any) => {
    if (layoutScreen == 'defaultScreen') {
      setRemoveHeaderFooter(layoutData)
      setChatButton(false)
      setButtonActions({ ...buttonActions, chat: false })
    } else if (layoutScreen === 'backSplitScreen') {
      setRemoveHeaderFooter(layoutData)
      backToVideoScreen ? setScreenStatus(false) : setScreenStatus(true)
      setDefaultMenuOverLay(false)
      setVideoScreenUser(1)
    } else if (layoutScreen === 'splitScreen') {
      setRemoveHeaderFooter(layoutData)
      setScreenStatus(true)
      setDefaultMenuOverLay(true)
      setBackToVideoScreen(true)
    } else if (layoutScreen === 'videoScreen') {
      setScreenStatus(true)
      setDefaultMenuOverLay(true)
      setBackToVideoScreen(false)
      setVideoScreenUser(0)
    }
  }

  const handleCallButton = (button: any) => {
    setButtonActions({
      ...buttonActions,
      [button.name]: !buttonActions[button.name],
    })

    switch (button.name) {
      case 'splitscreen':
        setScreenStatus(!screenStatus)
        break
      case 'video':
        handleVideoToggle()
        break
      case 'chat':
        setChatButton(!chatButton)
        break
      case 'voice':
        handleAudioToggle()

        break
      case 'endCall':
        handleLeaveCall()
        break
      case 'stethoscope':
        setMuteOtherUser(!muteOtherUser)
        break
    }
  }
  useEffect(() => {
    setButtonActions({
      ...buttonActions,
      voice: dataSource.currentMedia.isAudioPaused,
      video: dataSource?.currentMedia?.isVideoPaused,
    })
  }, [dataSource])
  useEffect(() => {
    setButtonActions((pre: any) => ({ ...pre, splitscreen: width <= 786 }))
  }, [width])

  return (
    <div className="flex flex-col md:h-screen bg-[#26323C] h-screen md:p-4 p-0 ">
      {removeHeaderFooter ? (
        <header className="h-[7%] justify-center gap-5 items-center flex text-white">
          {/* <div className="flex flex-col items-center justify-center">
            <span className="text-xs md:text-base lg:text-base font-medium md:font-bold lg:font-bold">
              Appointment
            </span>
            <span className="text-xs md:text-base lg:text-base font-medium md:font-bold lg:font-bold">
              10:30Am-10:45Am
            </span>
          </div> */}
          {/* <div className="flex flex-col items-center justify-center">
            <span className="text-xs md:text-base lg:text-base font-medium md:font-bold lg:font-bold">
              userId : {userName}
            </span>
            <div className="flex gap-3">
              <span className="text-xs md:text-base lg:text-base font-medium md:font-bold lg:font-bold">
                Appointment Id : {AppointmentId}
              </span>
              <button
                className="text-xs md:text-base lg:text-base font-medium md:font-bold lg:font-bold"
                onClick={copyText}
              >
                <CallId />
              </button>
            </div>
          </div> */}
        </header>
      ) : (
        ''
      )}
      {/* {Screen Layout} */}
      <section
        className={` ${removeHeaderFooter ? 'h-[83%] px-4' : 'h-[100%] p-5'}`}
      >
        {screen()}
      </section>
      {/* {Screen Layout} */}
      {/* {Footer} */}
      {removeHeaderFooter ? (
        <footer className="h-[10%] flex items-center justify-center w-full">
          <div className="flex justify-start items-start w-full md:px-7 flex-[1]">
            {width >= 435 ? <TimerComponent /> : ''}
          </div>
          <div className="flex md:gap-5 lg:gap-5 gap-2 w-full flex-[2] justify-center">
            {VideoButton?.map((button: any) => {
              return (
                <RoundedButtons
                  handleClick={() =>
                    handleCallButton(
                      button?.name === 'splitscreen' && width <= 768
                        ? false
                        : button
                    )
                  }
                  key={button.key}
                  icons={
                    !buttonActions[button.name]
                      ? cloneElement(button.icons, { fill: 'white' })
                      : button.icons
                  }
                  active={buttonActions[button.name]}
                />
              )
            })}
          </div>
          <div className="flex-[1]"></div>
        </footer>
      ) : (
        ''
      )}
      {/* {Footer} */}
    </div>
  )
}

export default VideoLayout
