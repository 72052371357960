type Role = 'DOCTOR' | 'SPECIALIST' | 'GUARDIAN' | 'PATIENT'

type AccessRules = {
  [role in Role]: {
    remove: Role[]
    mute: Role[]
    pin: Role[]
    hamburger: Role[]
  }
}
export const accessRules: AccessRules = {
  DOCTOR: {
    remove: ['SPECIALIST'],
    mute: ['PATIENT', 'GUARDIAN'],
    pin: ['PATIENT', 'GUARDIAN', 'SPECIALIST', 'DOCTOR'],
    hamburger: ['PATIENT'],
  },
  SPECIALIST: {
    remove: [],
    mute: ['PATIENT', 'GUARDIAN'],
    pin: ['PATIENT', 'GUARDIAN', 'DOCTOR', 'SPECIALIST'],
    hamburger: ['PATIENT'],
  },
  GUARDIAN: {
    remove: [],
    mute: [],
    pin: ['PATIENT', 'SPECIALIST', 'DOCTOR', 'GUARDIAN'],
    hamburger: [],
  },
  PATIENT: {
    remove: ['GUARDIAN'],
    mute: [],
    pin: ['GUARDIAN', 'SPECIALIST', 'DOCTOR', 'PATIENT'],
    hamburger: [],
  },
}
