// import { CloseIcon, SendIcon } from '../../assets/icons'
// import { useWindowResize } from '../../hook/window'

// const ChatScreen = ({ className, closeChat, value, onChange }: any) => {
//   const [width] = useWindowResize()
//   return (
//     <section
//       className={`lg:bg-white xl:bg-white w-full h-full rounded-lg border-2 flex flex-col
//       justify-between p-5 ${className}`}
//     >
//       <div className=" flex flex-row items-center justify-between font-bold text-lg lg:text-[#26323C] text-white">
//         <span> In-call message </span>
//         <button onClick={closeChat}>
//           <CloseIcon
//             className="w-5 cursor-pointer"
//             fill={width <= 768 ? 'white' : ''}
//           />
//         </button>
//       </div>
//       <div className="justify-self-end flex justify-between items-center gap-1">
//         <input
//           className="w-full lg:border-b-2 lg:border-b-[#acacad] rounded-md lg:text-[#26323C]
//           text-white bg-transparent lg:bg-white xl:bg-white"
//           placeholder="your message here"
//           type="text"
//           value={value}
//           onChange={onChange}
//         />
//         <SendIcon
//           className="cursor-pointer w-5"
//           fill={width <= 768 ? 'white' : 'black'}
//         />
//       </div>
//     </section>
//   )
// }
// export default ChatScreen
function ChatScreen({
  message,
  className,
  closeChat,
}: {
  className?: string
  message?: any
  closeChat?: () => void
}) {
  const date = new Date(message.dateTime).toLocaleDateString()
  if (message.isYou === true) {
    return (
      <div
        onClick={closeChat}
        className={`${className} flex flex-row justify-end mb-2 px-2 w-full`}
      >
        <div className="w-11/12">
          <p className="w-full text-gray-300 text-right text-xs">{date}</p>
          <div className="w-full p-1 px-2 bg-blue-500 rounded-xl">
            <span className="text-gray-300 text-sm">you</span>
            <p className="text-white">{message.data}</p>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="flex flex-row justify-start mb-2 px-2 w-full">
        <div className="w-11/12">
          <p className="w-full text-gray-300 text-left text-xs">{date}</p>
          <div className="w-full p-1 px-2 bg-slate-200 rounded-xl">
            <span className="text-gray-600 text-sm">{message.senderName}</span>
            <p>{message.data}</p>
          </div>
        </div>
      </div>
    )
  }
}
export default ChatScreen
