export interface PatientDetailsDataType {
  title: string
  value: string
}
export const PatientDetailsData = [
  {
    key: 1,
    title: 'Mobile Number',
    value: '+91 7655432897',
  },
  {
    key: 2,
    title: 'Date of Birth',
    value: '20-01-2002',
  },
  {
    key: 3,
    title: 'Gender',
    value: 'female',
  },
  {
    key: 4,
    title: 'Languages',
    value: 'Hindi , English',
  },
  {
    key: 5,
    title: 'Blood Group',
    value: 'B+',
  },
  {
    key: 6,
    title: 'Weight and Height',
    value: '72kg and 172cm',
  },
]
