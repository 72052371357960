import {
  EarImage,
  EyeImage,
  HandImage,
  UltrasoundImage,
} from '../assets/images'

export interface MedicalImagesDataType {
  source: JSX.Element | string | any
  name: string
}
export const MedicalImagesData = [
  {
    key: 1,
    title: 'Iris Scope',
    images: [
      {
        name: 'LeftEye',
        image: UltrasoundImage,
      },
      {
        name: 'RightEye',
        image: UltrasoundImage,
      },
    ],
  },
  {
    key: 2,
    title: 'Dermatoscope',
    images: [
      {
        name: 'Hand',
        image: EyeImage,
      },
      {
        name: 'Hand',
        image: EyeImage,
      },
    ],
  },
  {
    key: 2,
    title: 'Dermatoscope',
    images: [
      {
        name: 'Hand',
        image: EarImage,
      },
      {
        name: 'Hand',
        image: EarImage,
      },
    ],
  },
  {
    key: 2,
    title: 'Dermatoscope',
    images: [
      {
        name: 'Hand',
        image: HandImage,
      },
      {
        name: 'Hand',
        image: HandImage,
      },
    ],
  },
]
