import {
  Call,
  Chat,
  StethoscopeIcon,
  Vector,
  Video,
  Voice,
} from '../assets/icons'

export const VideoButton = [
  {
    key: 1,
    name: 'stethoscope',
    icons: (
      <StethoscopeIcon className="h-[20px] w-[23px] md:h-[25px] md:w-[28px]" />
    ),
  },
  {
    key: 2,
    name: 'chat',
    icons: <Chat className="h-[20px] w-[23px] md:h-[25px] md:w-[28px]" />,
  },
  {
    key: 3,
    name: 'splitscreen',
    icons: <Vector className="h-[20px] w-[23px] md:h-[25px] md:w-[28px]" />,
  },
  {
    key: 4,
    name: 'video',
    icons: <Video className="h-[20px] w-[23px] md:h-[25px] md:w-[28px]" />,
  },
  {
    key: 5,
    name: 'voice',
    icons: <Voice className="h-[20px] w-[23px] md:h-[25px] md:w-[28px]" />,
  },
  {
    key: 6,
    name: 'endCall',
    icons: <Call className="h-[20px] w-[23px] md:h-[25px] md:w-[28px]" />,
  },
]
