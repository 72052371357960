import { HeartbeatImage } from '../assets/images'
import { PieChart, LineChart } from '../components/Charts/Charts'
import StethescopeComponent from '../components/Tab/Stethescope'
import {
  MedicalImages,
  PatientHistory,
  Stethescope,
  Symptoms,
  Vital,
} from '../components/Tab/Tabs'
import {
  bloodGlucose,
  bloodPressure,
  hemoglobin,
  heartRate,
  spo2,
  symptomsHistoryData,
  cholestral,
  temperature,
  uricAcid,
} from './PatientHistoryChartData'

export const tabData = [
  {
    key: 1,
    label: 'Vital',
    content: <Vital />,
  },
  {
    key: 2,
    label: 'Medical Images',
    content: <MedicalImages />,
  },
  {
    key: 3,
    label: 'Symptoms',
    content: <Symptoms />,
  },
  {
    key: 4,
    label: 'Patient History',
    content: <PatientHistory />,
  },
  {
    key: 5,
    label: 'Stethescope',
    content: <StethescopeComponent />,
  },
]
