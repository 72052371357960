import { useEffect, useState } from 'react'
import { CloseIcon, HamburgerIcon, SendIcon } from '../../../assets/icons'
import {
  ActionButton,
  AudioComponent,
  MenuOverlay,
  ChatScreen,
  UserAvatar,
  Video,
} from '../../Components'

const DefaultScreen = ({
  chatMessages,
  message,
  onTypeMessage,
  isSendingMessage,
  sendMessage,
  handleLayout,
  handleOtherUserAudio,
  chatButton,
  splitMenuOverlay = false,
  closeChat,
  isMediaTrackAvailable,
  currentUserVideo,
  dataSource,
  removePeerById,
  currentUserType,
}: any) => {
  const userVideo = [dataSource?.currentMedia, ...dataSource.otherUsers]
  const userType = ['PATIENT', 'GUARDIAN']

  const [showMenuOverlay, setShowMenuOverlay] =
    useState<boolean>(splitMenuOverlay)
  const [showHambuger, setShowHambuger] = useState<boolean>(splitMenuOverlay)
  const [activeHamBugerUser, setActiveHamBugerUser] = useState<any>('')
  const [activeUser, setActiveUser] = useState<any>(0)

  const handleMenuOverlay = () => {
    setShowMenuOverlay(false)
    handleLayout(false, 'defaultScreen')
    setShowHambuger(false)
  }
  const handleCloseMenuOverLay = () => {
    setShowMenuOverlay(true)
    splitMenuOverlay
      ? handleLayout(true, 'defaultScreen')
      : handleLayout(true, 'backSplitScreen')
    setShowHambuger(true)
  }
  const handleCloseChat = () => {
    closeChat({ name: 'chat' })
  }
  const handleActionButtons = (currentUser: any, index: any, name: any) => {
    if (name === 'pin') {
      setActiveUser(index)
    } else if (name === 'remove') {
      removePeerById(currentUser?.producerPeerId || currentUser?.callId)
    }
  }
  const chatMessagesAsList = () => {
    let messages = []
    for (const messageKey in chatMessages) {
      messages.push(chatMessages[messageKey])
    }
    return messages
  }
  useEffect(() => {
    if (activeUser !== 0) {
      if (userVideo[0]?.userType === 'PATIENT') {
        setActiveHamBugerUser('PATIENT')
      }
    }
  }, [activeUser])

  return (
    <div
      className={`h-full  w-full ${chatButton ? 'flex justify-around' : ''}`}
    >
      <div
        className={`${
          chatButton ? 'block w-[70%] ' : 'flex  w-full'
        } h-full  gap-4`}
      >
        <section
          className={`group ${
            chatButton ? 'w-full h-[63%] mb-3' : 'w-[70%]'
          } rounded-xl `}
        >
          {/* {currentMedia } */}

          {showMenuOverlay ? (
            <>
              <div className=" relative h-full  border-2 border-[#808080] rounded-2xl">
                <ActionButton
                  className={`bg-white absolute items-center hidden right-3 top-[10px] group-hover:flex z-10`}
                  onClick={(name: any) =>
                    handleActionButtons(userVideo[activeUser], activeUser, name)
                  }
                  active={userVideo[activeUser]}
                  vertical={false}
                  currentUserType={currentUserType}
                />
                {activeUser === 0 ? (
                  userVideo[activeUser]?.current?.mediaStream ? (
                    <>
                      <Video
                        className=" object-cover h-full w-full rounded-xl "
                        muted={true}
                        srcObject={
                          currentUserVideo
                            ? userVideo[activeUser]?.current?.mediaStream
                            : null
                        }
                        streamPaused={!isMediaTrackAvailable?.video?.action}
                        userName={userVideo[activeUser]?.userName}
                      />
                    </>
                  ) : (
                    <div className=" flex h-full items-center justify-center">
                      <UserAvatar
                        name={userVideo[activeUser]?.userName}
                        className="h-[126px] w-[123px] rounded-full bg-white text-3xl font-bold"
                      />
                    </div>
                  )
                ) : userVideo[activeUser]?.medias?.video?.stream ? (
                  <>
                    <Video
                      className="object-cover h-full w-full rounded-xl "
                      muted={true}
                      srcObject={userVideo[activeUser]?.medias?.video?.stream}
                      streamPaused={
                        userVideo[activeUser]?.medias?.video?.paused
                      }
                      userName={userVideo[activeUser]?.userName}
                    />
                    <AudioComponent
                      srcObject={userVideo[activeUser].medias?.audio?.stream}
                      muted={userVideo[activeUser].medias?.audio?.paused}
                    />
                  </>
                ) : (
                  <div className=" flex h-full items-center justify-center">
                    <UserAvatar
                      name={userVideo[activeUser]?.userName}
                      className="h-[126px] w-[123px] rounded-full bg-white text-3xl font-bold"
                    />
                  </div>
                )}
                <p
                  className="text-xs md:text-base lg:text-base font-medium md:font-bold lg:font-bold text-white absolute bottom-3 left-2 
                  opacity-[0.75] truncate md:max-w-[172px] max-w-[115px]"
                >
                  {` ${userVideo[activeUser]?.userType} :  ${userVideo[activeUser]?.userName}`}
                </p>
              </div>
            </>
          ) : (
            <MenuOverlay closeMenuOverLay={handleCloseMenuOverLay} />
          )}
        </section>
        {/* {currentMedia } */}

        {/* {OtherUsers } */}
        <section
          className={`border-2
          border-[#808080] flex gap-4 rounded-xl ${
            chatButton ? 'flex-row w-full p-4 h-[35%]' : 'flex-col w-[30%] p-4 '
          }`}
        >
          {userVideo.length > 0 &&
            userVideo?.map((userVideoData: any, index: any) => {
              if (showMenuOverlay ? activeUser === index : activeUser === '')
                return null

              const muteAudio =
                userVideoData.medias?.audio?.paused === true ? true : false

              const videoSourceObject =
                index === 0
                  ? userVideoData.current?.mediaStream
                  : userVideoData.medias?.video?.stream

              const streamPaused =
                index === 0
                  ? !isMediaTrackAvailable?.video?.action
                  : userVideoData.medias?.video?.paused

              const audioSourceObject = userVideoData.medias?.audio?.stream
              return (
                <div
                  className={`group  flex-1 relative w-full rounded-2xl bg-black ${
                    showMenuOverlay ? 'h-[30%]' : 'h-[23%]'
                  }${chatButton ? 'h-[full]' : ''}`}
                >
                  {!userType.includes(userVideo[0]?.userType) &&
                    userVideo[index]?.userType === 'PATIENT' &&
                    showHambuger && (
                      <button
                        className="absolute left-2 top-[10px] z-10 "
                        onClick={handleMenuOverlay}
                      >
                        <HamburgerIcon className=" mix-blend-multiply h-[30px] w-[30px] z-10" />
                      </button>
                    )}
                  <ActionButton
                    currentUserType={currentUserType}
                    className={`bg-white absolute hidden right-3 top-[10px] group-hover:flex z-10`}
                    onClick={(name: any) =>
                      handleActionButtons(userVideoData, index, name)
                    }
                    active={userVideoData}
                    vertical={false}
                  />
                  <Video
                    className="object-cover h-full w-full  rounded-xl "
                    muted={true}
                    srcObject={videoSourceObject}
                    streamPaused={streamPaused}
                    userName={userVideoData?.userName}
                  />
                  <AudioComponent
                    srcObject={audioSourceObject}
                    muted={muteAudio}
                  />
                  <p
                    className="text-xs md:text-base lg:text-base font-medium md:font-bold lg:font-bold text-white absolute bottom-3 left-2 
                  opacity-[0.75] truncate md:max-w-[172px] max-w-[115px]"
                  >
                    {userVideoData?.userType} : {userVideoData?.userName}
                  </p>
                </div>
              )
            })}
        </section>
        {/* {OtherUsers } */}
      </div>
      {/* {Chat } */}
      {chatButton && (
        <section className="w-[25%] ease-in duration-300 ">
          <div
            className="lg:bg-white xl:bg-white w-full h-full rounded-lg border-2 flex flex-col 
              justify-between p-5"
          >
            <button
              className="flex justify-end pr-1"
              onClick={() => handleCloseChat()}
            >
              <CloseIcon className="h-6 w-6" />
            </button>
            <section className="flex-1 overflow-y-auto ">
              {chatMessagesAsList().map((message: any) => {
                return (
                  <>
                    <ChatScreen message={message} key={message.messageId} />
                  </>
                )
              })}
            </section>
            <>
              <div className="justify-self-end flex justify-between items-center gap-1">
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight 
                  focus:outline-none focus:shadow-outline"
                  type="text"
                  placeholder="your message here"
                  value={message}
                  onChange={(event) => onTypeMessage(event.target.value)}
                />

                {isSendingMessage === true ? (
                  <div className="send-message-loader absolute top-2 right-2 cursor-pointer" />
                ) : (
                  <button onClick={sendMessage}>
                    <SendIcon
                      fill="black"
                      className="absolute top-1 right-1 cursor-pointer hover:scale-110 "
                    />
                  </button>
                )}
              </div>
            </>
          </div>
        </section>
      )}
      {/* {Chat } */}
    </div>
  )
}

export default DefaultScreen
