import { useState } from 'react'

interface dropDown {
  options?: string[]
  placeholder?: string
  onChange?: (value: string) => void
  className?: any
}
const options = [{}]
const DropDown = ({ options, placeholder, onChange, className }: dropDown) => {
  const [value, setValue] = useState<string>('')
  const [showOptions, setShowOptions] = useState<boolean>(false)
  const changeValue = (val: string) => {
    setValue(val)
    setShowOptions(!showOptions)
  }

  return (
    <div className=" w-30">
      <section
        className={className}
        onClick={() => {
          setShowOptions(!showOptions)
        }}
      >
        <span>{value === '' ? placeholder : value}</span>
      </section>
      <div
        className={`flex-col  rounded-xl z-[1] absolute ${
          showOptions ? 'flex' : 'hidden'
        }`}
      >
        {options?.map((option) => (
          <div
            className="flex items-center p-4 "
            key={option}
            onClick={() => {
              changeValue(option)
              onChange?.(option)
            }}
          >
            {option}
          </div>
        ))}
      </div>
    </div>
  )
}

export default DropDown
