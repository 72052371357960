import { DashBoardIcon, NavbarLogo, TDMLocationIcon } from '../../assets/icons'

export const NavBar = () => {
  return (
    <div className="  h-full bg-[#53564E] pl-1 pt-10 grow">
      <NavbarLogo />
      <div className="flex flex-col item-center pt-10 pl-[4%]">
        <div className="flex flex-row items-center gap-3">
          <DashBoardIcon className="h-4 " />
          <p className="font-display text-sm text-white ">Dashboards</p>
        </div>
        <div className="flex flex-row items-center gap-3 pt-4">
          <TDMLocationIcon className="h-4 " />
          <p className="font-display text-sm text-white">TDM Location</p>
        </div>
      </div>
    </div>
  )
}
