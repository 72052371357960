export const labels = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'Aug',
  'sep',
  'oct',
  'nov',
  'dec',
]
export const heartRate = {
  labels: labels,
  datasets: [
    {
      label: 'HeartRate',
      data: [18, 12, 6, 9, 12, 3, 9],
      backgroundColor: ['#1FCB4F'],
      borderColor: ['#1FCB4F'],
    },
  ],
}
export const spo2 = {
  labels: labels,
  datasets: [
    {
      label: 'High',
      data: [9, 6, 3, 4.5, 6, 1.5, 4.5],
      borderColor: ['#1FCB4F'],
    },
  ],
}
export const temperature = {
  labels: labels,
  datasets: [
    {
      label: 'Systolic',
      data: [18, 12, 6, 9, 12, 3, 9],
      borderColor: ['#FF1B1B'],
    },
  ],
}
export const bloodPressure = {
  labels: labels,
  datasets: [
    {
      label: 'Systolic',
      data: [18, 12, 6, 9, 12, 3, 9],
      borderColor: ['#1FCB4F'],
    },
    {
      label: 'Diastolic',
      data: [9, 6, 3, 4.5, 6, 1.5, 4.5],
      borderColor: ['#FFC01E'],
    },
  ],
}
export const bloodGlucose = {
  labels: labels,
  datasets: [
    {
      label: 'Diastolic',
      data: [9, 6, 3, 4.5, 6, 1.5, 4.5],
      borderColor: ['#3767B1'],
    },
  ],
}
export const hemoglobin = {
  labels: labels,
  datasets: [
    {
      label: 'Systolic',
      data: [18, 12, 6, 9, 12, 3, 9],
      borderColor: ['#FFC01E'],
    },
    {
      label: 'Diastolic',
      data: [9, 6, 3, 4.5, 6, 1.5, 4.5],
      borderColor: ['#FF1B1B'],
    },
  ],
}
export const cholestral = {
  labels: labels,
  datasets: [
    {
      label: 'Diastolic',
      data: [9, 6, 3, 4.5, 6, 1.5, 4.5],
      borderColor: ['#FF1B1B'],
    },
  ],
}
export const uricAcid = {
  labels: labels,
  datasets: [
    {
      label: 'Diastolic',
      data: [9, 6, 3, 4.5, 6, 1.5, 4.5],
      borderColor: ['#FFC01E'],
    },
  ],
}
export const symptomsHistoryData = {
  labels: labels,
  datasets: [
    {
      label: 'Systolic',
      data: [18, 12, 6, 9, 12, 3, 9],
      borderColor: ['#1FCB4F'],
    },
    {
      label: 'Diastolic',
      data: [9, 6, 3, 4.5, 6, 1.5, 4.5],
      borderColor: ['#FFC01E'],
    },
  ],
}
export const LineChartoptions = {
  responsive: true,
  tension: 0.4,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  stacked: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart - Multi Axis',
    },
  },
  scales: {
    x: {
      grid: {
        drawBorder: false,
        display: false,
      },
    },
    y: {
      min: 0,
      max: 100,
      type: 'linear' as const,
      position: 'left' as const,
      ticks: {
        stepSize: 20,
      },
      grid: {
        color: '#383838',
      },
      border: {
        display: false,
        dash: (context: any) => {
          if (context.index === 0) {
            return [0, 0]
          } else {
            return [8, 4]
          }
        },
      },
    },
  },
  plugins1: [],
}
