// import { Pie } from 'react-chartjs-2'

// export const data = {
//   labels: [35, 20, 15, 20, 5, 30, 45],

//   datasets: [
//     {
//       label: 'My Labels',
//       data: [35, 20, 15, 20, 5, 30, 45],
//       backgroundColor: [
//         '#472EA3',
//         '#5E3FBE',
//         '#F4F0FD',
//         '#E5DAFB',
//         '#CBB6F8',
//         '#A88DEB',
//         '#886BD8',
//       ],
//       display: true,
//       font: { weight: 'bold' },
//       borderColor: ['transparent'],
//       radius: '50%',
//     },
//   ],
// }

// const PieChart = () => {
//   return <Pie data={data} />
// }

// export default PieChart

import { Pie } from 'react-chartjs-2'

export const data = {
  labels: [35, 20, 15, 20, 5, 30, 45],

  datasets: [
    {
      label: 'My Labels',
      data: [35, 20, 15, 20, 5, 30, 45],
      backgroundColor: [
        '#472EA3',
        '#5E3FBE',
        '#F4F0FD',
        '#E5DAFB',
        '#CBB6F8',
        '#A88DEB',
        '#886BD8',
      ],
      display: true,
      font: { weight: 'bold' },
      borderColor: ['transparent'],
      radius: '50%',
    },
  ],
}
  
const PieChart = () => {
  return <Pie data={data} />
}

export default PieChart
