import React from 'react'
import RoutesWrapper from './Routes'

const App: React.FC = () => {
  return (
    <>
      <RoutesWrapper />
    </>
  )
}
export default App
