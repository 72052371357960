interface InfoProps {
  TDMName: string
  TDMNumber: string | number
  TotalConsultation: string
  TotalConsultationCount: number
  TotalDoctor: string
  TotalDoctorCount: number
  TotalPatient: string
  TotalPatientCount: number
  Time: string | number
}

export const InfoTail = ({
  TDMName,
  TDMNumber,
  TotalConsultationCount,
  TotalConsultation,
  TotalDoctor,
  TotalDoctorCount,
  TotalPatient,
  TotalPatientCount,
  Time,
}: InfoProps) => {
  return (
    <div className="box-border  w-full border shadow-md rounded-lg border-slate-300 bg-white  ">
      <div className="flex flex-row pl-2  gap-x-[5%] py-6 grow">
        <div className="box-border w-[10%] bg-slate-100 rounded-2xl "></div>
        <section>
          <p className="font-display text-sm font-bold">{TDMName}</p>
          <div className="font-display text-sm ">{TDMNumber}</div>
        </section>
        <section>
          <p className="font-display text-sm">{TotalConsultation}</p>
          <div className="font-display text-sm font-bold">
            {TotalConsultationCount}
          </div>
        </section>
        <section>
          <p className="font-display text-sm">{TotalDoctor}</p>
          <div className="font-display text-sm font-bold">
            {TotalDoctorCount}
          </div>
        </section>
        <section>
          <p className="font-display text-sm">{TotalPatient}</p>
          <div className="font-display text-sm font-bold">
            {TotalPatientCount}
          </div>
        </section>
        <section>
          <p className="font-display text-sm">Avg Video Call</p>
          <div className="font-display text-sm font-bold">{Time}</div>
        </section>
      </div>
    </div>
  )
}
