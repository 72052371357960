type StethescopeImageCard = {
  image: any
  image2?: any
}
const StethescopeImageCard = ({ image, image2 }: StethescopeImageCard) => {
  return (
    <div className=" md:w-[180px] md:!h=[200px] lg:w-[300px] lg:!h=[280px]">
      <img className="w-full h-full" src={image} />
      {image2 && <img className="w-full h-full" src={image2} />}
    </div>
  )
}

export default StethescopeImageCard
