import { Device } from "mediasoup-client";
import { Producer, RtpParameters, RtpCapabilities } from "mediasoup-client/lib/types";

export async function createMediasoupDevice(rtpCapabilities: RtpCapabilities): Promise<Device | undefined> {
  try {
    const device = new Device();
    // https://mediasoup.org/documentation/v3/mediasoup-client/api/#device-load
    // Loads the device with RTP capabilities of the Router (server side)
    await device.load({
      routerRtpCapabilities: rtpCapabilities,
    });
    // once the device loads, create transport
    // createSendTransport();
    return device;
  } catch (error) {
    throw new Error("Unable to Create Mediasoup Device")
  }
}