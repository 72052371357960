import { useState } from 'react'
import { PatientDetailsData, tabData } from '../../constants'
import { PatientDetailsDataType } from '../../constants/PatientDetailsData'
import { Patient } from '../../assets/images'
import { Tabs } from '../Tab'
import { useWindowResize } from '../../hook/window'
import { CloseIcon } from '../../assets/icons'
import UserAvatar from '../UserAvatar'
import UserDetailCard from '../Cards/UserDetailCard'

const MenuOverlay = ({ screen, closeMenuOverLay }: any) => {
  const [width] = useWindowResize()

  return (
    <>
      <div className="relative h-full w-full bg-white rounded-2xl">
        <section
          className={`grid grid-flow-col justify-around items-center md:gap-6 gap-2 pl-2 md:pl-3  ${
            width > 768 ? '' : 'md:px-3 '
          }`}
        >
          <UserAvatar
            name="Albert John"
            className={`h-[95px] w-[95px] md:rounded-full lg:rounded-full rounded-xl ${
              width > 768 ? ' ' : 'h-20 w-20 '
            }`}
            src={Patient}
          />
          <div className="mb-2 ">
            <span className="md:text-2xl lg:text-2xl text-lg text-[#26323C] font-bold">
              Esther Howard
            </span>
            <div
              className={`flex flex-wrap md:w-[80%] lg:w-[80%] w-full ${
                width > 768 ? 'gap-5' : '  md:gap-6 gap-3'
              }`}
            >
              {PatientDetailsData?.map(
                (patientData: PatientDetailsDataType) => {
                  return (
                    <UserDetailCard
                      title={patientData.title}
                      value={patientData.value}
                    />
                  )
                }
              )}
            </div>
          </div>
          <button
            className="mt-4 h-full mr-2 flex justify-center "
            onClick={closeMenuOverLay}
          >
            <CloseIcon className="w-5 cursor-pointer " />
          </button>
        </section>
        <Tabs tabData={tabData} />
      </div>
    </>
  )
}

export default MenuOverlay
