import { VitalCard } from '../../Cards/Cards'
import { LiveEcg } from '../../../assets/images'
import { LiveVitalsData, BasicBloodTestData } from '../../../constants'
import {
  BasicBloodTestDataType,
  LiveVitalsDataType,
} from '../../../constants/LiveVitals'

const Vital = () => {
  return (
    <div className="flex flex-col scroll overflow-y-auto max-h-[50%] lg:p-6 md:p-[10px]">
      <div className="p-2 md:p-0 lg:p-0 flex flex-col items-center md:items-start lg:items-start">
        <span className="text-xs md:text-sm lg:text-base text-black lg:text-[#59636C] md:text-[#59636C] font-bold pb-3">
          Live ECG
        </span>
        <img src={LiveEcg} />
      </div>
      <div className="lg:pt-3 flex flex-col items-center md:justify-center md:items-start lg:items-start ">
        <span className="text-xs md:text-sm lg:text-base text-black lg:text-[#59636C] md:text-[#59636C] font-bold ">
          Live Vitals
        </span>
        <div className="flex flex-wrap gap-4 lg:justify-start justify-center items-center w-[90%] lg:w-[90%] pt-3 first-letter first-letter ">
          {LiveVitalsData?.map((livedata: LiveVitalsDataType) => (
            <VitalCard
              icon={livedata.icon}
              value={livedata.value}
              content={livedata.content}
            />
          ))}
        </div>
      </div>
      <div className="flex flex-col lg:pt-3 pt-2 items-center  justify-center md:items-start lg:items-start ">
        <span className="text-xs md:text-sm lg:text-base text-black lg:text-[#59636C] md:text-[#59636C] font-bold ">
          Basic Blood Test
        </span>
        <div className="flex gap-4 pt-3 w-full flex-wrap justify-center lg:justify-start">
          {BasicBloodTestData?.map((bloodTestData: BasicBloodTestDataType) => (
            <VitalCard
              icon={bloodTestData.icon}
              value={bloodTestData.value}
              content={bloodTestData.content}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Vital
