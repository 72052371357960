import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
ChartJS.register(ArcElement, Tooltip, Legend)
interface PieChartProps {
  TotalConsultation: number
  Result: string
  ResultCount: number
  ResultPercentage: string | number
  PieData: number[]
}

const PieChartCard = ({
  TotalConsultation,
  Result,
  ResultCount,
  ResultPercentage,
  PieData,
}: PieChartProps) => {
  const data = {
    datasets: [
      {
        data: PieData,
        backgroundColor: ['#59E2E5', '#284AD0'],
      },
    ],
  }

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  }
  return (
    <div className="box-border rounded-3xl shadow-lg">
      <div className="flex flex-row  py-5 ">
        <p className="pl-7 font-display not-italic text-lg font-normal text-[#26323C]">
          {' '}
          Total Consultation
        </p>
        <div className="font-display not-italic text-xl font-normal text-[#26323C] pl-52">
          {' '}
          {TotalConsultation}
        </div>
      </div>
      <hr />
      <div className="flex flex-row">
        <div className="h-48 w-48 pl-3 py-5">
          <Pie data={data} options={options} />
        </div>
        <div className="flex flex-col gap-8 pl-8  ">
          <div className="flex flex-row pt-5">
            <div className="box-border w-16 h-14 border-2 border-sky-200 rounded-xl content-center ">
              <p className="text-center pt-3 font-display not-italic text-base font-bold text-[#26323C]">
                {ResultPercentage}
              </p>
            </div>
            <p className="pl-5 font-display not-italic text-xl font-normal text-[#26323C]">
              {ResultCount}
              <p className="font-display not-italic text-base font-normal text-gray-400 ">
                {Result}
              </p>
            </p>
          </div>
          <div className="flex flex-row">
            <div className="box-border w-16 h-14 border-2 border-sky-200 rounded-xl content-center">
              <p className="text-center pt-3 font-display not-italic text-base font-bold text-[#26323C]">
                {ResultPercentage}
              </p>
            </div>
            <p className="pl-5 font-display not-italic text-xl font-normal text-[#26323C]">
              {ResultCount}
              <p className="font-display not-italic text-base font-normal text-gray-400">
                {Result}
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PieChartCard
