interface TotalCountCardProps {
  Total: string
  Count: number
  icon?: any
}
const TotalCountCard = ({ Total, Count, icon }: TotalCountCardProps) => {
  return (
    <div
      className="rounded-2xl border-slate-300 shadow-gray-300 
     w-full py-3 grid gap-2 pl-2.5 bg-white shadow-md "
    >
      <span className="text-slate-300 w-8">{icon}</span>
      <div>
        <p className="text-xs">{Total}</p>
        <p className="font-bold text-3xl ">{Count}</p>
      </div>
    </div>
  )
}

export default TotalCountCard
