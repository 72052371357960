import React, { useEffect, useRef, useState } from 'react'
import socketIOClient, { Socket, io } from 'socket.io-client'
import { stateType } from '../../types'
import { MessageType, SocketCallBackResponseType } from './types'
import { CHAT_SOCKET_ENDPOINT } from './configs'

function useChat() {
  const [socket, setSocket] = useState<null | Socket>(null)
  const [chatMessages, setChatMessages]: stateType<
    { [key: string]: MessageType } | undefined
  > = useState<{ [key: string]: MessageType }>()
  const [message, setMessage] = useState<string>('')
  const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false)
  const currentUserCallPeerId: React.MutableRefObject<string> =
    useRef<string>('')

  useEffect(() => {
    if (socket) {
      subscriberToSocketEvents()
    }
  }, [socket])

  function connectChatSocket({
    peerId,
    userId,
    userName,
    callId,
  }: {
    peerId: string
    userId: string
    userName: string
    callId: string
  }) {
    currentUserCallPeerId.current = peerId
    const _socket = socketIOClient(CHAT_SOCKET_ENDPOINT, {
      rejectUnauthorized: false,
    })
    _socket.on('connection-success', ({ socketId }) => {
      setSocket(_socket)
      _socket.emit(
        'join-chat',
        {
          peerId,
          userId,
          userName,
          callId,
        },
        async ({ status, data }: SocketCallBackResponseType) => {
          if (status === 'ERROR') return
        }
      )
    })
  }

  function closeChatSocket() {
    socket?.disconnect()
    if (isSendingMessage) setIsSendingMessage(false)
  }

  function subscriberToSocketEvents() {
    socket!.onAny((event, ...args) => {
      console.log('-----**-----')
      console.log('event : ', event)
      console.log('args : ', args)
      console.log('-----***-----\n\n')
    })

    socket!.on('new-message-received', (message: MessageType) => {
      message.isYou =
        message.senderCallPeerId === currentUserCallPeerId.current
          ? true
          : false
      setChatMessages((prevState) => {
        return {
          ...prevState,
          [message.messageId]: message,
        }
      })
    })

    socket?.on('disconnect', (reason) => {
      closeChatSocket()
    })
  }

  function sendMessage() {
    if (message.trim().length > 0) {
      setIsSendingMessage(true)
      socket?.emit(
        'send-message',
        { message: message },
        async ({ status, data }: SocketCallBackResponseType) => {
          setIsSendingMessage(false)
          setMessage('')
        }
      )
    }
  }

  const onTypeMessage = (data: string) => {
    if (isSendingMessage) return
    setMessage(data)
  }

  return {
    connectChatSocket,
    closeChatSocket,
    chatMessages,
    message,
    onTypeMessage,
    isSendingMessage,
    sendMessage,
  }
}

export default useChat
