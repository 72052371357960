import { useState } from 'react'

export function Tabs({ className = '', tabData, row = true }: any) {
  const [activeTabIndex, setActiveTabIndex] = useState(1)
  const chat = localStorage.getItem('chat')
  console.log('f1', chat)
  return (
    <>
      <div
        className={`${className}${
          row ? 'flex-wrap h-[50px]' : 'flex-col w-[12%] h-[55vh] '
        } flex border-b  bg-[#26323c14] justify-evenly 
         font-display font-medium md:font-bold lg:font-bold text-black gap-3`}
      >
        {/* Loop through tab data and render button for each. */}
        {tabData?.map((tab: any, idx: any) => {
          return (
            <button
              key={idx}
              className={`text-xs md:text-sm lg:text-base py-2 transition-colors duration-300 border-blue-800  ${
                tab.key === activeTabIndex
                  ? row
                    ? 'border-b-2'
                    : 'border-r-2 '
                  : 'border-transparent font-light text-slate-500 '
              }`}
              // Change the active tab on click.
              onClick={() => setActiveTabIndex(tab.key)}
            >
              {tab.label}
            </button>
          )
        })}
      </div>
      {/* Show active tab content. */}
      <section
        className={`${
          row
            ? `${
                chat
                  ? 'lg:max-h-[50%] max-h-[60%] !overflow-y-auto !scroll'
                  : ' lg:max-h-[70%] max-h-[60%] !overflow-y-auto !scroll'
              }`
            : 'w-[80%] h-full flex justify-center items-center'
        }`}
      >
        {tabData[activeTabIndex - 1]?.content}
      </section>
    </>
  )
}
