import { MutableRefObject } from "react";
import { DEFAULT_MEDIA_CONSTRAINTS } from "./configs";
import { MediaTrackType } from "./types";

export async function getMediaStream(): Promise<{
  videoTrack: MediaStreamTrack;
  audioTrack: MediaStreamTrack;
  mediaStream: MediaStream;
}> {
  await navigator.mediaDevices.getUserMedia({ audio: true, video: { facingMode: 'user' } });
  await navigator.mediaDevices.enumerateDevices();
  (async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioOutputDevices = devices.filter(device => device.kind === 'audiooutput');
      console.log('Audio Output Devices:', audioOutputDevices);
    } catch (error) {
      console.error('Error enumerating devices:', error);
    }
  })();
  
  const mediaStream = await navigator.mediaDevices.getUserMedia(DEFAULT_MEDIA_CONSTRAINTS);
  // Get the video and audio tracks from the media stream
  const videoTrack = mediaStream.getVideoTracks()[0];
  const audioTrack = mediaStream.getAudioTracks()[0];

  navigator.mediaDevices.ondevicechange = async function (event) {
    console.log('event : ', event);
    const device = await navigator.mediaDevices.enumerateDevices();
    (async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioOutputDevices = devices.filter(device => device.kind === 'audiooutput');
        console.log('Audio Output Devices:', audioOutputDevices);
      } catch (error) {
        console.error('Error enumerating devices:', error);
      }
    })();
    
    console.log('device : ', device);
  };

  return {
    videoTrack: videoTrack,
    audioTrack: audioTrack,
    mediaStream: mediaStream,
  }
};

export async function listenMediaStream(mediaTrack: MutableRefObject<MediaTrackType | undefined>, onMediaDeviceChange: Function): Promise<boolean> {
  const { videoTrack, audioTrack, mediaStream } = await getMediaStreamAndTrack();
  const PERMISSION_GRANTED = (videoTrack && audioTrack && mediaStream) ? true : false;

  if (PERMISSION_GRANTED === false) return false;

  mediaTrack.current = {
    video: videoTrack as MediaStreamTrack,
    audio: audioTrack as MediaStreamTrack,
    mediaStream: mediaStream as MediaStream,
  };

  navigator.mediaDevices.ondevicechange = async function () {
    const { videoTrack, audioTrack, mediaStream } = await getMediaStreamAndTrack();
    const PERMISSION_GRANTED = (videoTrack && audioTrack && mediaStream) ? true : false;
    if (PERMISSION_GRANTED === false) return false;

    mediaTrack.current = {
      video: videoTrack as MediaStreamTrack,
      audio: audioTrack as MediaStreamTrack,
      mediaStream: mediaStream as MediaStream,
    };
    onMediaDeviceChange();
  };

  return true;
}



async function getMediaStreamAndTrack() {
  try {
    await navigator.mediaDevices.getUserMedia({ audio: true, video: { facingMode: 'user' } });
    await navigator.mediaDevices.enumerateDevices();
    (async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioOutputDevices = devices.filter(device => device.kind === 'audiooutput');
        console.log('Audio Output Devices:', audioOutputDevices);
      } catch (error) {
        console.error('Error enumerating devices:', error);
      }
    })();
    
    const mediaStream = await navigator.mediaDevices.getUserMedia(DEFAULT_MEDIA_CONSTRAINTS);
    // Get the video and audio tracks from the media stream
    const videoTrack = mediaStream.getVideoTracks()[0];
    const audioTrack = mediaStream.getAudioTracks()[0];

    return {
      videoTrack: videoTrack,
      audioTrack: audioTrack,
      mediaStream: mediaStream,
    }
  } catch (error) {
    return {
      videoTrack: null,
      audioTrack: null,
      mediaStream: null,
    }
  }
}


export function generateRandomUUID(): string {
  //@ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export function getRandomTwoDigitNumber(): string {
  return `${Math.floor(Math.random() * 90 + 10)}`;
}

export function debounce(fn: Function, ms = 300) {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};
