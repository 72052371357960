// import { Tabs } from '..'
// import { PatientHistoryTabData } from '../../../constants/PatientHistoryTabData'
// import { useWindowResize } from '../../../hook/window'

// const PatientHistory = () => {
//   const [width] = useWindowResize()
//   return (
//     <div className="flex">
//       {width >= 475 ? (
//         <div className="w-[80%] flex ">
//           <Tabs row={false} tabData={PatientHistoryTabData} />
//         </div>
//       ) : (
//         <div className="flex flex-col gap-2">
//           {PatientHistoryTabData?.map((el: any) => {
//             return <>{el.content}</>
//           })}
//         </div>
//       )}
//     </div>
//   )
// }

// export default PatientHistory
import { Tabs } from '..'
import { PatientHistoryTabData } from '../../../constants'
import { useWindowResize } from '../../../hook/window'

const PatientHistory = () => {
  const [width] = useWindowResize()
  return (
    <div className="flex">
      {width >= 475 ? (
        <div className="w-[80%] flex ">
          <Tabs row={false} tabData={PatientHistoryTabData} />
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          {PatientHistoryTabData?.map((el: any) => {
            return <>{el.content}</>
          })}
        </div>
      )}
    </div>
  )
}
export default PatientHistory
