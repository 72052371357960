import { PopUpImage } from '../../assets/images'

const EndScreen = () => {
  return (
    <div className="h-screen w-full flex flex-col items-center justify-center">
      <h1 className="text-[#65dd39] text-6xl font-extrabold">
        Call Ended Successfully ...
      </h1>
      <img src={PopUpImage} />
    </div>
  )
}

export default EndScreen
