import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { LineChartoptions } from '../../../constants'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export default function LineChart({ data }: any) {
  return (
    <div className="flex flex-col gap-5">
      <div className="flex justify-end gap-4">
        <p>1D</p>
        <span className="h-5 w-px  bg-[#838383]" />
        <p> 7D </p>
        <span className="h-5 w-px inline-block bg-[#838383]" />
        <p> 1M </p>
        <span className="h-5 w-px inline-block bg-[#838383]" />
        <p> 6M </p>
        <span className="h-5 w-px inline-block bg-[#838383]" />
        <p> 1Y </p>
      </div>
      <Line options={LineChartoptions} data={data} />
    </div>
  )
}

// export default LineChart
