import { useEffect, useState } from 'react'
import { ActionButtonsData } from '../../constants/ActionButtonsData'
import AccessIcon from '../AccessIcon'
import { UserTypesType } from '../../pages/Call/types'

const ActionButton = ({
  vertical,
  onClick,
  className,
  active,
  currentUserType,
}: {
  vertical: boolean
  onClick?: any
  className?: string
  active?: any
  currentUserType: UserTypesType
}) => {
  const [callActionButtons, setCallActionButtons] = useState<any>({
    pin: false,
    mute: false,
    remove: false,
  })
  useEffect(() => {
    setCallActionButtons({
      ...callActionButtons,
      mute: active?.isAudioPaused ? true : false,
    })
  }, [active])

  return (
    <>
      <div
        className={`${
          vertical ? 'flex-col ' : ' '
        }  rounded-[10px]  w-fit items-center gap-3 bg-[rgba(61,57,137,0.10)] 
          border border-[rgba(61,57,137,0.10)] p-2 cursor-pointer ${className}`}
      >
        {ActionButtonsData?.map((ActionButtons: any) => (
          <AccessIcon
            role={currentUserType}
            action={ActionButtons?.name}
            userType={active?.userType}
          >
            <button
              className={`${
                callActionButtons[ActionButtons.name]
                  ? 'bg-[#d92029d9] rounded-full flex items-center justify-center w-[25px] h-[25px] '
                  : 'bg-white'
              }`}
              onClick={() => onClick(ActionButtons?.name)}
            >
              {ActionButtons.icon}
            </button>
          </AccessIcon>
        ))}
      </div>
    </>
  )
}

export default ActionButton
