import React, { useState } from 'react'
import { Bar, Chart } from 'react-chartjs-2'
import {
  Chart as Chartjs,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js'
import DropDown from '../../Dropdown'

interface BarChartProps {
  dataOne: number[]
  dataTwo: number[]
}

Chartjs.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend)

const BarChart = ({ dataOne, dataTwo }: BarChartProps) => {
  const data = {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],

    datasets: [
      {
        label: 'Health',
        data: dataOne,

        backgroundColor: '#55CAF0',
      },
      {
        label: 'Prescription',
        data: dataTwo,
        backgroundColor: '#487DC0',
      },
    ],
  }
  const options: any = {
    type: 'bar',
    data,
    options: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        border: {
          dash: [8, 4],
        },
        id: 'y',
        display: true,
        ticks: {
          callback: function (val: any) {
            return val + '%'
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  return (
    <div className="box-border border-slate-300 shadow-md rounded-2xl bg-white h-full w-full">
      <div className="flex flex-row py-2  ">
        <p className="font-display text-lg font-normal text-[#26323C]  pl-10 ">
          Health Reports & Prescription
        </p>

        <div className="flex flex-row ">
          <p className="font-display text-lg  text-[#26323C] flex flex-row items-center pl-14 gap-2">
            <span className=" bg-[#55CAF0] w-3 h-3 rounded-full "></span>
            Health
          </p>
          <p className="font-display text-lg  text-[#26323C] flex flex-row items-center pl-5 gap-2">
            <span className=" bg-[#487DC0] w-3 h-3 rounded-full"></span>
            Prescription
          </p>
          <div className="">
            <DropDown
              placeholder={'2022'}
              options={['item1', 'item2']}
              className={
                'flex items-center w-24  h-7 justify-center rounded relative bg-white ml-2 border px-3 '
              }
            />
          </div>
        </div>
      </div>
      <hr />
      <div className=" px-3 py-3  ">
        <Bar data={data} options={options} />
      </div>
    </div>
  )
}

export default BarChart
