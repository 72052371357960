import { useNavigate } from "react-router-dom";

export default function UserAlreadyExist(){
  const navigator = useNavigate()

    return (
        <div className="h-full w-full flex justify-center items-center" >
            <div className="flex flex-col items-center" >
            <h1> User already exist </h1>
            <button className="bg-blue-700 shadow-lg rounded-lg p-3 m-3 text-white " onClick={()=>{
                    navigator('/',{replace:true})
                }} >Go back to Home</button>
            </div>
        </div>
    );
}