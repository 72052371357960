import { useState } from 'react'
import axios from 'axios'
import { Loginbg } from '../../assets/images'

export const LoginPage = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const handleEmail = (e: any) => {
    setEmail(e.target.value)
    console.log(email)
  }
  const handlePassword = (e: any) => {
    setPassword(e.target.value)
    console.log(password)
  }
  const handleApi = (e:any) => {
    e.preventDefault()
    axios
      .post('https://api.bridgehealth.care/v1/local/user/login',{email:email,password:password,  "phoneNumber": "7891234567",})
      .then((response) => console.log(response))
      .catch((err) => console.log(err))
    console.log({ email, password })
  }
  return (
    <div className=" bg-cover w-full flex flex-col items-center justify-center">
      <img src={Loginbg} className="h-screen w-full " />
      <div className="absolute text-center ">
        <p className="font-display text-white text-4xl font-semibold shadow-gray-600 tracking-wide">
          Admin Login
        </p>
        <p className="text-sm text-[#383838] pt-3 pb-2">
          Welcom To Admin Login
        </p>
        <p className="text-white pr-[56%]">Admin ID / Email</p>
        <input
          className="rounded-lg h-10 w-80 text-center outline-none "
          value={email}
          onChange={handleEmail}
        ></input>
        <p className="text-white pr-[75%] pt-2 outline-none ">Password</p>
        <input
          className="rounded-lg h-10 w-80 text-center"
          type="password"
          value={password}
          onChange={handlePassword}
        ></input>
        <div className="pt-8">
          <button
            className="text-white box-border text-lg rounded-lg font-bold bg-black h-10 w-80 "
            onClick={handleApi}
          >
            Sign in
          </button>
        </div>
      </div>
      <div
        className="font-outline-2 font-outline-4 absolute right-[5%] bottom-[5%]  text-right text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-slate-400 to-slate-800 
      "
      >
        <p>No</p>
        <p>Delay in</p>
        <p>Healthcare</p>
      </div>
    </div>
  )
}
