import { useNavigate } from 'react-router-dom'
import { MutableRefObject, useEffect, useRef, useState } from 'react'
import socketIOClient, { Socket } from 'socket.io-client'
import { debounce, listenMediaStream } from './utils'
import {
  Producer,
  Consumer,
  RtpCapabilities,
  Device,
  AppData,
  TransportOptions,
  Transport,
  DtlsParameters,
} from 'mediasoup-client/lib/types'
import { createMediasoupDevice } from './mediasoup'
import { stateType } from '../../types'
import useChat from './useChat'
import {
  MediaTrackType,
  MediaTypes,
  MediasoupLocalHolder,
  OtherUser,
  SocketCallBackResponseType,
  isMediaTrackAvailable,
} from './types'
import { MEDIASOUP_SOCKET_ENDPOINT } from './configs'
import useForceReRender from '../../hook/useForceReRender'
import useQuery from '../../hook/query'

// what if user didn't permit media access ?

// http://localhost:4000/call?user-id=123&user-type=DOCTOR&user-name=doctor-1&Appointment-id=A1234QW
// http://localhost:4000/call?user-id=1234&user-type=PATIENT&user-name=patient-1&Appointment-id=A1234QW
export function logger(event: string, args: any) {
  console.log('-----**-----')
  console.log('event : ', event)
  console.log('args : ', args)
  console.log('-----***-----\n\n')
}

interface JoinOrInitiateCallI {
  userType: 'DOCTOR' | 'SPECIALIST' | 'PATIENT' | 'GUARDIAN'
  userName: string
  userId: string
  callId: string | null
}

function useCall() {
  const [isCallConnecting, setIsCallConnecting] = useState(false)
  const forceReRender = useForceReRender()
  const navigator = useNavigate()
  const query = useQuery()
  const isNetworkIssueRef = useRef(false)
  const [isNetworkIssue, setIsNetworkIssue] = useState(false)
  const [isSocketConnected, setIsSocketConnected] = useState(false)

  useEffect(() => {
    isNetworkIssueRef.current = isNetworkIssue
  }, [isNetworkIssue])
  const shouldTriggerMediaAfterReConnection = useRef({
    video: false,
    audio: false,
  })

  const [videoCallMetaData, setVideoCallMetaData] = useState<{
    callId: string
    peerId: string
  }>({
    callId: '',
    peerId: '',
  })
  const callIdRef: MutableRefObject<string | null> = useRef(null)
  const socketRef = useRef<null | Socket>(null)
  const joinOrInitiateCallDetailsRef = useRef<JoinOrInitiateCallI | null>(null)

  const [isMediaTrackAvailable, setIsMediaTrackAvailable] =
    useState<isMediaTrackAvailable>({
      video: {
        device: false, // video in on state
        action: false, // video in off state
        mediasoup: false,
      },
      audio: {
        device: false,
        action: false,
        mediasoup: false,
      },
      mediaStream: false,
    })

  const isMediaTrackAvailableRef = useRef<isMediaTrackAvailable>()

  useEffect(() => {
    isMediaTrackAvailableRef.current = { ...isMediaTrackAvailable }
  }, [isMediaTrackAvailable])

  const mediaTrack = useRef<MediaTrackType>()
  const mediasoupHolder = useRef<MediasoupLocalHolder>({
    device: null,
    producerTransport: null,
    producer: {
      video: null,
      audio: null,
    },
    consumerTransports: {},
    rtpCapabilities: null,
  })

  const [otherUsers, setOtherUsers]: stateType<OtherUser[] | undefined> =
    useState<OtherUser[]>()

  const onClientDisconnectFunc = useRef<() => void>()

  const {
    connectChatSocket,
    closeChatSocket,
    chatMessages,
    message,
    onTypeMessage,
    isSendingMessage,
    sendMessage,
  } = useChat()

  function subscriberToSocketEvents() {
    socketRef.current?.onAny((event, ...args) => {
      console.log('-----**-----')
      console.log('event : ', event)
      console.log('args : ', args)
      console.log('-----***-----\n\n')
    })

    socketRef.current!.on('ping', () => {
      socketRef.current?.emit('pong')
    })

    socketRef.current!.on('user-already-exist', () => {
      socketRef.current?.disconnect()
      navigator('/call-fallback/user-already-exist', { replace: true })
    })

    socketRef.current!.on('exceeded-max-user-per-call', () => {
      socketRef.current?.disconnect()
      navigator('/call-fallback/exceeded-max-user-per-call', { replace: true })
    })

    socketRef.current!.on(
      'NEW_USER_JOINED',
      ({ userId, peerId, userName, userType }) => {
        if (userId === joinOrInitiateCallDetailsRef.current?.userId) return

        setOtherUsers((prevState) => {
          if (prevState?.find((user) => user.producerPeerId === peerId))
            return prevState
          /**
           * @situation when new_user is a reconnected user
           * Instead of adding as a new user, replace the peerId of the user
           */
          if (prevState?.find((user) => user.userId === userId)) {
            const result: OtherUser[] | undefined = prevState?.map(
              (otherUser) => {
                if (otherUser.userId === userId) {
                  return {
                    ...otherUser,
                    producerPeerId: peerId,
                  } as OtherUser
                } else {
                  return otherUser
                }
              }
            )
            return result
          } else {
            const result = [
              ...(prevState || []),
              {
                userId,
                producerPeerId: peerId,
                userName: userName,
                userType: userType,
              },
            ]
            return result
          }
        })
      }
    )

    socketRef.current!.on(
      'USER_STARTED_STREAMING',
      ({ userId, producerPeerId, producerId }) => {
        _createRecvWebrtcTransport(producerId, userId, producerPeerId)
      }
    )

    socketRef.current!.on(
      'producer-stream-paused',
      async ({
        producerPeerId,
        mediaType,
      }: {
        producerPeerId: string
        mediaType: 'video' | 'audio'
      }) => {
        setOtherUsers((prevState) => {
          const result = prevState?.map((otherUser) => {
            if (otherUser.producerPeerId === producerPeerId) {
              const audio = otherUser.medias?.audio
              const video = otherUser.medias?.video
              const user: OtherUser = {
                ...otherUser,
                medias: {
                  video: video,
                  audio: audio,
                  [mediaType]: {
                    stream: otherUser.medias![mediaType]!.stream,
                    paused: true,
                  },
                },
              }
              return user
            } else {
              return otherUser
            }
          })
          return result
        })
      }
    )

    socketRef.current!.on(
      'producer-stream-resumed',
      async ({
        producerPeerId,
        mediaType,
      }: {
        producerPeerId: string
        mediaType: 'video' | 'audio'
      }) => {
        setOtherUsers((prevState) => {
          const result = prevState?.map((otherUser) => {
            if (otherUser.producerPeerId === producerPeerId) {
              const audio = otherUser.medias?.audio
              const video = otherUser.medias?.video
              const user: OtherUser = {
                ...otherUser,
                medias: {
                  video: video,
                  audio: audio,
                  [mediaType]: {
                    stream: otherUser.medias![mediaType]!.stream,
                    paused: false,
                  },
                },
              }
              return user
            } else {
              return otherUser
            }
          })
          return result
        })
      }
    )

    socketRef.current?.on('user-left-the-call', ({ peerId }) => {
      const leftUser: OtherUser | undefined = otherUsers?.find(
        (otherUser) => otherUser.producerPeerId === peerId
      )
      if (leftUser) {
        leftUser.medias?.video?.consumer.close()
        leftUser.medias?.audio?.consumer.close()
      }
      const CallId = query.get('Appointment-id')
      if (CallId === peerId) {
        navigator('/end-screen', { replace: true })
      }

      setOtherUsers((prevState) =>
        prevState?.filter((otherUser) => otherUser.producerPeerId !== peerId)
      )
    })

    socketRef.current?.on('force-mute-audio', () => {
      pauseAudio()
    })

    socketRef.current?.on('force-un-mute-audio', () => {
      resumeAudio()
    })

    socketRef.current?.on('disconnect', (reason) => {
      console.log('T1 socketRef.current.disconnect')
      onNetworkDisconnect()
    })

    socketRef.current?.on('force-disconnect', () => {
      onCallDisconnect()
    })
  }

  function onNetworkDisconnect() {
    const _isMediaTrackAvailableRef = isMediaTrackAvailableRef.current
    if (
      _isMediaTrackAvailableRef &&
      _isMediaTrackAvailableRef.mediaStream === true &&
      _isMediaTrackAvailableRef.video.device === true &&
      _isMediaTrackAvailableRef.video.action === true
    ) {
      shouldTriggerMediaAfterReConnection.current.video = true
    }
    if (
      _isMediaTrackAvailableRef &&
      _isMediaTrackAvailableRef.mediaStream === true &&
      _isMediaTrackAvailableRef.audio.device === true &&
      _isMediaTrackAvailableRef.audio.action === true
    ) {
      shouldTriggerMediaAfterReConnection.current.audio = true
    }
    setIsNetworkIssue(true)
    onCallDisconnect()
  }

  function onCallDisconnect() {
    const _mediasoupHolder = mediasoupHolder.current
    _mediasoupHolder.producerTransport?.close()
    for (const transportId in _mediasoupHolder.consumerTransports) {
      const consumer: Transport = _mediasoupHolder.consumerTransports[
        transportId
      ] as Transport
      consumer.close()
    }
    _mediasoupHolder.device = null
    _mediasoupHolder.producer.video?.close()
    _mediasoupHolder.producer.audio?.close()
    mediasoupHolder.current = {
      device: null,
      producerTransport: null,
      producer: {
        video: null,
        audio: null,
      },
      consumerTransports: {},
      rtpCapabilities: null,
    }
    // mediaTrack.current?.audio.stop();
    // mediaTrack.current?.video.stop();
    socketRef.current?.removeAllListeners()
    socketRef.current = null
    setOtherUsers([])
    setVideoCallMetaData({
      callId: videoCallMetaData.callId,
      peerId: '',
    })
    setIsMediaTrackAvailable({
      video: {
        device: false,
        action: false,
        mediasoup: false,
      },
      audio: {
        device: false,
        action: false,
        mediasoup: false,
      },
      mediaStream: false,
    })
    mediaTrack.current = undefined
    closeChatSocket()
    if (onClientDisconnectFunc.current) {
      onClientDisconnectFunc.current()
    }
    setIsSocketConnected(false)
  }

  function onSocketDisconnect(callBack: () => void) {
    onClientDisconnectFunc.current = callBack
  }

  async function joinOrCreateCall({
    userType,
    userName,
    userId,
    callId,
  }: JoinOrInitiateCallI) {
    setIsCallConnecting(true)
    joinOrInitiateCallDetailsRef.current = {
      userType,
      userName,
      userId,
      callId,
    }
    await new Promise((resolve) => setTimeout(resolve, 1000))
    if (!socketRef.current) {
      connectToSocket()
    }
  }

  function connectToSocket() {
    if (!socketRef.current) {
      const _socket = socketIOClient(MEDIASOUP_SOCKET_ENDPOINT, {
        rejectUnauthorized: false,
      })
      _socket.on('connection-success', () => {
        socketRef.current = _socket
        subscriberToSocketEvents()
        setIsSocketConnected(true)
      })
    }
  }

  useEffect(() => {
    if (isSocketConnected) {
      joinOrInitiateCall()
    } else if (isSocketConnected === false && isNetworkIssue === true) {
      connectToSocket()
      tryConnectingElseCloseConnection()
    }
  }, [isSocketConnected])

  function tryConnectingElseCloseConnection() {
    const maxRetries = 30
    let retries = 1
    const interval = setInterval(() => {
      if (isNetworkIssueRef.current === true && !socketRef.current) {
        if (retries >= maxRetries) {
          clearInterval(interval)
          navigator('/call-fallback/some-thing-went-wrong', { replace: true })
        }
      } else if (isNetworkIssueRef.current === false && socketRef.current) {
        clearInterval(interval)
      }
      retries = retries + 1
    }, 1000)
  }

  function joinOrInitiateCall() {
    if (!socketRef.current) return
    const _callId = joinOrInitiateCallDetailsRef.current?.callId
      ? joinOrInitiateCallDetailsRef.current.callId
      : callIdRef.current
      ? callIdRef.current
      : null
    socketRef.current.emit(
      'join-or-create-call',
      {
        userType: joinOrInitiateCallDetailsRef.current?.userType,
        userName: joinOrInitiateCallDetailsRef.current?.userName,
        userId: joinOrInitiateCallDetailsRef.current?.userId,
        callId: _callId,
        isReconnect: isNetworkIssueRef.current,
      },
      async ({ status, data }: SocketCallBackResponseType) => {
        if (status === 'ERROR') return
        setVideoCallMetaData({
          callId: data.callId as string,
          peerId: data.peerId as string,
        })
        callIdRef.current = data.callId as string
        connectChatSocket({
          peerId: data.peerId,
          callId: data.callId,
          userId: joinOrInitiateCallDetailsRef.current?.userId as string,
          userName: joinOrInitiateCallDetailsRef.current?.userName as string,
        })
        mediasoupHolder.current.rtpCapabilities =
          data.rtpCapabilities as RtpCapabilities
        logger('RtpCapabilities ', data.rtpCapabilities)
        mediasoupHolder.current.device = (await createMediasoupDevice(
          mediasoupHolder.current.rtpCapabilities as RtpCapabilities
        )) as Device
        spinUpPreviousResources()
        setIsCallConnecting(false)
      }
    )
  }

  function spinUpPreviousResources() {
    if (isNetworkIssueRef.current === false) return
    if (!mediasoupHolder.current.device) return

    if (shouldTriggerMediaAfterReConnection.current.video === true) {
      setTimeout(() => {
        toggleVideo()
      }, 1000)
    }
    if (shouldTriggerMediaAfterReConnection.current.audio === true) {
      setTimeout(() => {
        toggleAudio()
      }, 1000)
    }
    setIsNetworkIssue(false)
  }

  function toggleVideo() {
    if (
      isMediaTrackAvailable.video.action === false &&
      isMediaTrackAvailable.video.device === false
    ) {
      getVideo()
    } else if (
      isMediaTrackAvailable.video.action === true &&
      isMediaTrackAvailable.video.device === true
    ) {
      pauseVideo()
    } else if (
      isMediaTrackAvailable.video.action === false &&
      isMediaTrackAvailable.video.device === true
    ) {
      resumeVideo()
    }
  }

  function toggleAudio() {
    if (
      isMediaTrackAvailable.audio.action === false &&
      isMediaTrackAvailable.audio.device === false
    ) {
      getAudio()
    } else if (
      isMediaTrackAvailable.audio.action === true &&
      isMediaTrackAvailable.audio.device === true
    ) {
      pauseAudio()
    } else if (
      isMediaTrackAvailable.audio.action === false &&
      isMediaTrackAvailable.audio.device === true
    ) {
      resumeAudio()
    }
  }

  function toggleOtherUserAudio({
    peerId,
    mute,
  }: {
    peerId: string
    mute: boolean
  }) {
    if (mute === true) {
      muteOtherUserAudio(peerId)
    } else {
      unMuteOtherUserAudio(peerId)
    }
  }

  function muteOtherUserAudio(peerId: string) {
    console.log(peerId, 'p1')
    socketRef.current?.emit(
      'mute-other-user-audio',
      { producerPeerId: peerId },
      async ({ status, data }: SocketCallBackResponseType) => {}
    )
  }

  function unMuteOtherUserAudio(peerId: string) {
    socketRef.current?.emit(
      'un-mute-other-user-audio',
      { producerPeerId: peerId },
      async ({ status, data }: SocketCallBackResponseType) => {}
    )
  }

  async function getAudio() {
    try {
      const gotMedia = await getMedia()
      if (!gotMedia) throw new Error('Please permit Media permission')
      setIsMediaTrackAvailable((prevState) => {
        const result = {
          video: {
            device: prevState.video.device,
            action: prevState.video.action,
            mediasoup: prevState.video.mediasoup,
          },
          audio: {
            device: mediaTrack.current?.audio ? true : false,
            action: true,
            mediasoup: prevState.audio.mediasoup,
          },
          mediaStream: mediaTrack.current?.mediaStream ? true : false,
        }
        return result
      })
      await _createSendWebrtcTransport('audio')
    } catch (error) {}
  }

  async function getVideo() {
    try {
      const gotMedia = await getMedia()
      if (!gotMedia) throw new Error('Please permit Media permission')
      setIsMediaTrackAvailable((prevState) => {
        const result = {
          video: {
            device: mediaTrack.current?.video ? true : false,
            action: true,
            mediasoup: prevState.video.mediasoup,
          },
          audio: {
            device: prevState.audio.device,
            action: prevState.audio.action,
            mediasoup: prevState.audio.mediasoup,
          },
          mediaStream: mediaTrack.current?.mediaStream ? true : false,
        }
        return result
      })
      await _createSendWebrtcTransport('video')
    } catch (error) {}
  }

  async function getMedia(): Promise<boolean | undefined> {
    if (
      !(
        mediaTrack.current?.audio &&
        mediaTrack.current.video &&
        mediaTrack.current.mediaStream
      )
    ) {
      const result = await listenMediaStream(
        mediaTrack as MutableRefObject<MediaTrackType>,
        onMediaDeviceChange
      )
      return result
    } else if (
      mediaTrack.current?.audio &&
      mediaTrack.current.video &&
      mediaTrack.current.mediaStream
    ) {
      return true
    }
  }

  const onMediaDeviceChange = debounce(() => {
    const videoProducer: Producer<AppData> | null =
      mediasoupHolder.current.producer.video
    const audioProducer: Producer<AppData> | null =
      mediasoupHolder.current.producer.audio

    if (videoProducer && mediaTrack.current!.video) {
      videoProducer.replaceTrack({
        track: mediaTrack.current!.video,
      })
    }
    if (audioProducer && mediaTrack.current!.audio) {
      audioProducer.replaceTrack({
        track: mediaTrack.current!.audio,
      })
    }
    // So that video will re-render
    forceReRender()
  })

  function pauseVideo() {
    mediasoupHolder.current.producer.video?.pause()
    setIsMediaTrackAvailable((prevState) => ({
      ...prevState,
      video: {
        action: false,
        device: prevState.video.device,
        mediasoup: prevState.video.mediasoup,
      },
    }))
  }

  function resumeVideo() {
    mediasoupHolder.current.producer.video?.resume()
    setIsMediaTrackAvailable((prevState) => ({
      ...prevState,
      video: {
        action: true,
        device: prevState.video.device,
        mediasoup: prevState.video.mediasoup,
      },
    }))
    if (mediasoupHolder.current.producer.video?.paused === true) {
    }
  }

  function pauseAudio() {
    mediasoupHolder.current.producer.audio?.pause()
    setIsMediaTrackAvailable((prevState) => {
      return {
        ...prevState,
        audio: {
          action: false,
          device: prevState.audio.device,
          mediasoup: prevState.audio.mediasoup,
        },
      }
    })
  }

  function resumeAudio() {
    mediasoupHolder.current.producer.audio?.resume()
    setIsMediaTrackAvailable((prevState) => {
      return {
        ...prevState,
        audio: {
          action: true,
          device: prevState.audio.device,
          mediasoup: prevState.audio.mediasoup,
        },
      }
    })
    if (mediasoupHolder.current.producer.audio?.paused === true) {
    }
  }

  async function _createSendWebrtcTransport(mediaType: MediaTypes) {
    socketRef.current!.emit(
      'create-webrtc-transport',
      {},
      ({ status, data }: SocketCallBackResponseType) => {
        if (status === 'ERROR') return
        const producerTransport = (
          mediasoupHolder.current.device as Device
        ).createSendTransport(data as TransportOptions)
        mediasoupHolder.current.producerTransport = producerTransport
        _subscribeToProduceTransportEvents(producerTransport)
        _produceMediaToTransport(producerTransport, mediaType)
      }
    )
  }

  const _subscribeToProduceTransportEvents = (producerTransport: Transport) => {
    producerTransport.on(
      'connect',
      async (
        { dtlsParameters }: { dtlsParameters: DtlsParameters },
        callback,
        errorCallback
      ) => {
        logger('transportId ', producerTransport.id)
        logger('dtlsParameters ', dtlsParameters)

        try {
          socketRef.current!.emit(
            'webrtc-transport-connect',
            {
              transportId: producerTransport.id,
              dtlsParameters: dtlsParameters,
            },
            ({ status }: SocketCallBackResponseType) => {
              if (status === 'SUCCESS') {
                logger('_subscribeToProduceTransportEvents ', 'SUCCESS')
                callback()
              }
            }
          )
        } catch (error) {
          errorCallback(error as Error)
        }
      }
    )
    producerTransport.on(
      'produce',
      async (parameters, callback, errorCallback) => {
        try {
          logger('producerTransport-produce :: parameters ', parameters)
          // tell the server to create a Producer
          // with the following parameters and produce
          // and expect back a server side producer id
          // see server's socketRef.current.on('transport-produce', ...)
          socketRef.current!.emit(
            'webrtc-produce-stream',
            {
              kind: parameters.kind,
              rtpParameters: parameters.rtpParameters,
              transportId: producerTransport.id,
            },
            ({ status, data }: SocketCallBackResponseType) => {
              // Tell the transport that parameters were transmitted and provide it with the
              // server side producer's id.
              if (status !== 'SUCCESS') {
                throw new Error(data.error)
              }
              logger('webrtc-produce-stream :: ', 'SUCCESS')
              callback({ id: data.producerId })
            }
          )
        } catch (error) {
          errorCallback(error as Error)
        }
      }
    )
  }

  const _produceMediaToTransport = async (
    producerTransport: Transport,
    mediaType: MediaTypes
  ) => {
    const producer = await producerTransport.produce({
      track:
        mediaType === 'video'
          ? mediaTrack.current!.video
          : mediaTrack.current!.audio,
    })

    logger('_produceMediaToTransport :: producer ', producer)

    // producer.replaceTrack
    mediasoupHolder.current.producer[mediaType] = producer
    producer.observer.on('trackended', () => {
      console.log('track ended')
      // close media track
    })
    producer.observer.on('transportclose', () => {
      console.log('transport ended')
      // close media track
    })

    producer.observer.on('pause', () => {
      socketRef.current?.emit(
        'producer-pause-stream',
        { producerId: producer.id },
        ({ status, data }: SocketCallBackResponseType) => {
          if (status === 'SUCCESS') {
            setIsMediaTrackAvailable((prevState) => ({
              ...prevState,
              [mediaType]: {
                action: prevState[mediaType].action,
                device: prevState[mediaType].device,
                mediasoup: false,
              },
            }))
          }
        }
      )
    })

    producer.observer.on('resume', () => {
      socketRef.current?.emit(
        'producer-resume-stream',
        { producerId: producer.id },
        ({ status, data }: SocketCallBackResponseType) => {
          if (status === 'SUCCESS') {
            setIsMediaTrackAvailable((prevState) => ({
              ...prevState,
              [mediaType]: {
                action: prevState[mediaType].action,
                device: prevState[mediaType].device,
                mediasoup: true,
              },
            }))
          }
        }
      )
      // close media track
    })
  }

  const _createRecvWebrtcTransport = (
    producerId: string,
    userId: string,
    producerPeerId: string
  ) => {
    try {
      const consumerTransportState: { [key: string]: Transport } | null =
        mediasoupHolder.current.consumerTransports
      // return if already consuming
      if (consumerTransportState && consumerTransportState[producerId]) return

      socketRef.current!.emit(
        'create-webrtc-transport',
        {},
        ({ status, data }: SocketCallBackResponseType) => {
          if (status === 'ERROR') return
          logger('_createRecvWebrtcTransport :: data : ', data)
          const serverTransportId = data.id
          const consumerTransport = (
            mediasoupHolder.current.device as Device
          ).createRecvTransport(data as TransportOptions)
          mediasoupHolder.current.consumerTransports = {
            ...mediasoupHolder.current.consumerTransports,
            [serverTransportId]: consumerTransport,
          }
          _subscribeToConsumeTransportEvents(
            consumerTransport,
            serverTransportId,
            producerId
          )
          _consumeMediaFromTransport(
            consumerTransport,
            serverTransportId,
            producerId,
            userId,
            producerPeerId
          )
        }
      )
    } catch (error) {}
  }

  const _subscribeToConsumeTransportEvents = async (
    consumerTransport: Transport,
    serverTransportId: string,
    producerId: string
  ) => {
    consumerTransport.on(
      'connect',
      async (
        { dtlsParameters }: { dtlsParameters: DtlsParameters },
        callback,
        errorCallback
      ) => {
        logger(
          '_subscribeToConsumeTransportEvents :: dtlsParameters - ',
          dtlsParameters
        )
        try {
          socketRef.current!.emit(
            'webrtc-transport-connect',
            { transportId: serverTransportId, dtlsParameters: dtlsParameters },
            ({ status }: SocketCallBackResponseType) => {
              if (status === 'SUCCESS') {
                logger(
                  '_subscribeToConsumeTransportEvents - connect :: ',
                  'SUCCESS'
                )
                callback()
              }
            }
          )
        } catch (error) {
          errorCallback(error as Error)
        }
      }
    )
  }

  const _consumeMediaFromTransport = async (
    consumerTransport: Transport,
    serverTransportId: string,
    producerId: string,
    userId: string,
    producerPeerId: string
  ) => {
    socketRef.current!.emit(
      'webrtc-consume-stream',
      {
        rtpCapabilities: mediasoupHolder.current.rtpCapabilities,
        producerId: producerId,
        serverTransportId: serverTransportId,
      },
      async ({ status, data }: SocketCallBackResponseType) => {
        if (status === 'ERROR') return

        if (data && data.error) {
          console.log('Cannot Consume')
          return
        }

        // then consume with the local consumer transport
        // which creates a consumer
        const consumer: Consumer = await consumerTransport.consume({
          id: data.id,
          producerId: data.producerId,
          kind: data.kind,
          rtpParameters: data.rtpParameters,
        })

        logger(
          '_consumeMediaFromTransport rtpParameters : ',
          data.rtpParameters
        )

        // destructure and retrieve the video track from the producer
        const { track } = consumer
        logger('_consumeMediaFromTransport track :: ', track)

        const stream = new MediaStream([track])
        setOtherUsers((prevState) => {
          const result = prevState?.map((otherUser) => {
            if (otherUser.producerPeerId === producerPeerId) {
              const audio = otherUser.medias?.audio
              const video = otherUser.medias?.video
              const user: OtherUser = {
                userId: otherUser.userId,
                producerPeerId: otherUser.producerPeerId,
                producerId: producerId,
                userName: otherUser.userName,
                userType: otherUser.userType,
                medias: {
                  video: video,
                  audio: audio,
                  [data.kind]: {
                    stream: stream,
                    paused: false,
                    consumer: consumer,
                  },
                },
              }
              return user
            } else {
              return otherUser
            }
          })
          return result
        })

        consumer.on('transportclose', () => {
          consumer.close()
        })
        // the server consumer started with media paused
        // so we need to inform the server to resume
        socketRef.current!.emit(
          'webrtc-consume-stream-resume',
          {
            serverConsumerId: data.serverConsumerId,
          },
          ({ status }: SocketCallBackResponseType) => {
            if (status === 'SUCCESS') {
              console.log('consumed')
            }
          }
        )
      }
    )
  }

  const leaveCall = () => {
    socketRef.current?.disconnect()
    closeChatSocket()
    navigator('/end-screen', { replace: true })
  }
  const removePeerById = (peerId: string) => {
    socketRef.current?.emit('remove-peer-by-id', { peerId })
  }

  return {
    leaveCall,
    joinOrCreateCall,
    toggleVideo,
    toggleAudio,
    videoCallMetaData,
    isMediaTrackAvailable,
    onSocketDisconnect,
    otherUsers,
    mediaTrack,
    setVideoCallMetaData,
    toggleOtherUserAudio,
    chatMessages,
    message,
    onTypeMessage,
    isSendingMessage,
    sendMessage,
    isCallConnecting,
    muteOtherUserAudio,
    removePeerById,
    isNetworkIssue,
  }
}

export default useCall
