import { TdmsInfoCard } from '.'
import AppBar from '../../AppBar'
import DropDown from '../../Dropdown'
import { InfoTail } from '../../Info Tail'
import { NavBar } from '../../Navbar'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Map } from '../../Map'
import {
  Back,
  Mylocation,
  Next,
  ScreenZoom,
  ZoomIn,
  ZoomOut,
} from '../../../assets/icons'


const infoTail = [
  {
    TDMName: 'TDMName',
    TDMNumber: 'ID00r95',
    TotalConsultation: 'TotalConsultation',
    TotalConsultationCount: 23,
    TotalDoctor: 'TotalDoctor',
    TotalDoctorCount: 45,
    TotalPatient: 'TotalPatient',
    TotalPatientCount: 16,
    Time: '15:00 min',
  },
  {
    TDMName: 'TDMName 2',
    TDMNumber: 'ID00r95',
    TotalConsultation: 'TotalConsultation',
    TotalConsultationCount: 23,
    TotalDoctor: 'TotalDoctor',
    TotalDoctorCount: 45,
    TotalPatient: 'TotalPatient',
    TotalPatientCount: 16,
    Time: '15:00 min',
  },

  {
    TDMName: 'TDMName 3',
    TDMNumber: 'ID00r95',
    TotalConsultation: 'TotalConsultation',
    TotalConsultationCount: 23,
    TotalDoctor: 'TotalDoctor',
    TotalDoctorCount: 45,
    TotalPatient: 'TotalPatient',
    TotalPatientCount: 16,
    Time: '15:00 min',
  },

  {
    TDMName: 'TDMName 4',
    TDMNumber: 'ID00r95',
    TotalConsultation: 'TotalConsultation',
    TotalConsultationCount: 23,
    TotalDoctor: 'TotalDoctor',
    TotalDoctorCount: 45,
    TotalPatient: 'TotalPatient',
    TotalPatientCount: 16,
    Time: '15:00 min',
  },

  {
    TDMName: 'TDMName 5',
    TDMNumber: 'ID00r95',
    TotalConsultation: 'TotalConsultation',
    TotalConsultationCount: 23,
    TotalDoctor: 'TotalDoctor',
    TotalDoctorCount: 45,
    TotalPatient: 'TotalPatient',
    TotalPatientCount: 16,
    Time: '15:00 min',
  },
  {
    TDMName: 'TDMName 5',
    TDMNumber: 'ID00r95',
    TotalConsultation: 'TotalConsultation',
    TotalConsultationCount: 23,
    TotalDoctor: 'TotalDoctor',
    TotalDoctorCount: 45,
    TotalPatient: 'TotalPatient',
    TotalPatientCount: 16,
    Time: '15:00 min',
  },
  {
    TDMName: 'TDMName 5',
    TDMNumber: 'ID00r95',
    TotalConsultation: 'TotalConsultation',
    TotalConsultationCount: 23,
    TotalDoctor: 'TotalDoctor',
    TotalDoctorCount: 45,
    TotalPatient: 'TotalPatient',
    TotalPatientCount: 16,
    Time: '15:00 min',
  },
  {
    TDMName: 'TDMName 5',
    TDMNumber: 'ID00r95',
    TotalConsultation: 'TotalConsultation',
    TotalConsultationCount: 23,
    TotalDoctor: 'TotalDoctor',
    TotalDoctorCount: 45,
    TotalPatient: 'TotalPatient',
    TotalPatientCount: 16,
    Time: '15:00 min',
  },
  {
    TDMName: 'TDMName 5',
    TDMNumber: 'ID00r95',
    TotalConsultation: 'TotalConsultation',
    TotalConsultationCount: 23,
    TotalDoctor: 'TotalDoctor',
    TotalDoctorCount: 45,
    TotalPatient: 'TotalPatient',
    TotalPatientCount: 16,
    Time: '15:00 min',
  },
  {
    TDMName: 'TDMName 5',
    TDMNumber: 'ID00r95',
    TotalConsultation: 'TotalConsultation',
    TotalConsultationCount: 23,
    TotalDoctor: 'TotalDoctor',
    TotalDoctorCount: 45,
    TotalPatient: 'TotalPatient',
    TotalPatientCount: 16,
    Time: '15:00 min',
  },
  {
    TDMName: 'TDMName 5',
    TDMNumber: 'ID00r95',
    TotalConsultation: 'TotalConsultation',
    TotalConsultationCount: 23,
    TotalDoctor: 'TotalDoctor',
    TotalDoctorCount: 45,
    TotalPatient: 'TotalPatient',
    TotalPatientCount: 16,
    Time: '15:00 min',
  },
  {
    TDMName: 'TDMName 5',
    TDMNumber: 'ID00r95',
    TotalConsultation: 'TotalConsultation',
    TotalConsultationCount: 23,
    TotalDoctor: 'TotalDoctor',
    TotalDoctorCount: 45,
    TotalPatient: 'TotalPatient',
    TotalPatientCount: 16,
    Time: '15:00 min',
  },
  
]
export const NewTabInfo = () => {
  

  
  return (
    <div className="   w-full h-full">
      <div className="w-[10%] fixed  h-full">
        <NavBar />
      </div>
      <div className="flex flex-col ml-[10%] ">
        <header className="w-full">
          <AppBar adminName="Admin Name" staff="Staff" />
        </header>
        <section className=" flex flex-row pt-5 pl-5 ml-[5%]  ">
          <div className="grid  grid-cols-[100px_minmax(100px,_1fr)_800px]">
            <p className="font-display text-base font-bold grow">Top TDMS</p>

            <div className="">
              <DropDown
                options={['item1', 'item2']}
                placeholder={'Filter'}
                className={
                  'flex items-center gap-2 h-7 justify-center rounded relative bg-[#f3f4f6] px-4 '
                }
              />
            </div>
            <div className=" justify-self-end">
              <DropDown
                options={['item1', 'item2']}
                placeholder={'Last 7 days'}
                className={
                  'flex items-center gap-2 h-7 w-36  justify-center rounded relative bg-[#f3f4f6] px-4'
                }
              />
            </div>
          </div>
        </section>
        <section className=" px-[1.5%] py-[1.5%] w-full  grow">
          
          <div className="flex flex-row w-full grow-0">
            <section className="grow w-[40%] pl-[1.5%] ">
              <ol className=" grid gap-3 pt-2">
                {infoTail.slice(0,10).map((data) => {
                  return (
                    <InfoTail
                      key={uuidv4()}
                      TDMName={data.TDMName}
                      TDMNumber={data.TDMNumber}
                      TotalConsultation={data.TotalConsultation}
                      TotalConsultationCount={data.TotalConsultationCount}
                      TotalDoctor={data.TotalDoctor}
                      TotalDoctorCount={data.TotalDoctorCount}
                      TotalPatient={data.TotalPatient}
                      TotalPatientCount={data.TotalPatientCount}
                      Time={data.Time}
                    />
                  )
                })}
              </ol>
              <div className="flex flex-row gap-[70%] py-[1.2%]">
                <span className=" flex flex-row w-44 text-xs rounded bg-[#e2e8f0] pl-4 items-center ">
                  1-11 of 200
                  <div className="flex flex-row pl-2">
                    <Back /> <Next />
                  </div>
                </span>
                <span className="rounded bg-[#e2e8f0] px-4">Back</span>
              </div>
            </section>
            <section className=" pr-2 pt-2 grow ">
              <div className="w-full pl-[1.5%]">
                <Map />
              </div>
              <footer className="flex flex-row py-4 gap-[55%] pl-3 ">
                <span className="flex flex-row rounded bg-[#f3f4f6] gap-1  pl-2 pt-2 ">
                  <div className="pr-5">
                    <Mylocation className=" w-8" />
                  </div>
                  <ZoomIn className="w-8" />
                  <div className="pt-1">
                    <span className="w-px h-5 bg-black"></span>
                  </div>
                  <ZoomOut className="w-8" />
                </span>

                <span className="rounded bg-[#f3f4f6]  py-2 px-2">
                  <ScreenZoom className="w-8 " />
                </span>
              </footer>
            </section>
          </div>
        </section>
      </div>
    </div>
  )
}
