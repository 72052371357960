import {
  Cities,
  CurrentConsultation,
  CurrentUser,
  Doctor,
  Patient,
  State,
  TDMs,
} from '../../assets/icons'

import AppBar from '../../components/AppBar'
import { HealthReport } from '../../components/Cards/HealthReports'
import TotalCountCard from '../../components/Cards/TotalCountCard'
import BarChart from '../../components/Charts/BarChart'
import {
  LineChartCard,
  PieChart,
  PieChartCard,
} from '../../components/Charts/Charts'
import { DoughnutPieChart } from '../../components/Charts/DoughnutPieChart'
import { NavBar } from '../../components/Navbar'
import { TdmsInfoCard } from '../../components/Cards/TdmsInfoCard'

export const DashBoard = () => {
  const val = [80, 70]
  return (
    <div className=" bg-[#f9fafb] relative w-full h-full">
      <div className="w-[10%] fixed  h-full">
        <NavBar />
      </div>

      <div className="flex flex-col ml-[10%] bg-[#f9fafb]">
        <div className="w-full">
          <AppBar adminName="Admin Name" staff="Staff" />
        </div>

        <div className="grid grid-cols-7 gap-x-4 pl-10 pr-8 pt-5">
          <TotalCountCard
            icon={<Patient />}
            Total="Total Patients"
            Count={12}
          />
          <TotalCountCard icon={<TDMs />} Total="Total TDMs" Count={12} />
          <TotalCountCard icon={<Doctor />} Total="Total Doctors" Count={12} />
          <TotalCountCard icon={<Cities />} Total="Total Cities" Count={12} />
          <TotalCountCard icon={<State />} Total="Total states" Count={12} />
          <TotalCountCard
            icon={<CurrentConsultation />}
            Total="Current Consulation"
            Count={12}
          />
          <TotalCountCard icon={<CurrentUser />} Total="Current" Count={12} />
        </div>
        <section className=" grid  pt-[1.5%] grid-cols-[700px_minmax(300px,_1fr)] ">
          <div className=" row-span-2  px-[1.5%] ">
            <BarChart
              dataOne={[100, 80, 56, 30, 20, 86, 40, 19]}
              dataTwo={[70, 13, 26, 76, 40, 39, 20, 10]}
            />
          </div>

          <div className="pr-[5%]">
            <PieChartCard
              TotalConsultation={45}
              Result={'Success'}
              ResultCount={750}
              ResultPercentage={'75%'}
              PieData={[23, 40]}
            />
          </div>
          <div className=" pt-[1.5%] pr-[5%]">
            <HealthReport HealthCount={355} PrescriptionCount={567} />
          </div>
        </section>
        <section className="flex flex-row w-full pt-[1.5%]">
          <div className="w-[30%] pl-[1.5%]">
            <DoughnutPieChart
              SpecialistCount={'2567'}
              GuardianCount={'4.5k'}
              datas={val}
              SpecialistData={23}
              GuardianData={33}
            />
          </div>
          <div className=" px-[1.5%] grow">
            <LineChartCard dataOne={[10, 38]} dataTwo={[13, 48]} />
          </div>
        </section>
        <section className=" px-[1.5%] py-[1.5%] w-full grow">
          <div>
            <TdmsInfoCard />
          </div>
          <div className="pt-[1.5%]">
            <TdmsInfoCard />
          </div>
        </section>
      </div>
    </div>
  )
}
