import LineChart from '../components/Charts/LineChart'
import PieChart from '../components/Charts/PieChart'
import {
  bloodGlucose,
  bloodPressure,
  cholestral,
  heartRate,
  hemoglobin,
  spo2,
  temperature,
  uricAcid,
} from './PatientHistoryChartData'

export const PatientHistoryTabData = [
  {
    key: 1,
    label: 'HR',
    content: (
      <div className="h-full w-full">
        <span className="text-[#222222] px-4 text-sm md:text-base lg:text-base font-bold md:font-bold lg:font-bold">
          Heart Rate
        </span>
        <LineChart data={heartRate} />
      </div>
    ),
  },
  {
    key: 2,
    label: 'Spo2',
    content: (
      <div className="h-full w-full">
        <span className="text-[#222222] px-4 text-sm md:text-base lg:text-base font-bold md:font-bold lg:font-bold">
          spo2
        </span>
        <LineChart data={spo2} />
      </div>
    ),
  },
  {
    key: 3,
    label: 'Temp',
    content: (
      <div className="h-full w-full">
        <span className="text-[#222222] px-4 text-sm md:text-base lg:text-base font-bold md:font-bold lg:font-bold">
          Temperature
        </span>
        <LineChart data={temperature} />
      </div>
    ),
  },
  {
    key: 4,
    label: 'BP',
    content: (
      <div className="h-full w-full">
        <span className="text-[#222222] px-4 text-sm md:text-base lg:text-base font-bold md:font-bold lg:font-bold">
          Blood Pressure
        </span>
        <LineChart data={bloodPressure} />
      </div>
    ),
  },
  {
    key: 5,
    label: 'BG',
    content: (
      <div className="h-full w-full">
        <span className="text-[#222222] px-4 text-sm md:text-base lg:text-base font-bold md:font-bold lg:font-bold">
          Blood Glucose
        </span>
        <LineChart data={bloodGlucose} />
      </div>
    ),
  },
  {
    key: 6,
    label: 'Hb',
    content: (
      <div className="h-full w-full">
        <span className="text-[#222222] px-4 text-sm md:text-base lg:text-base font-bold md:font-bold lg:font-bold">
          Hemoglobin
        </span>
        <LineChart data={hemoglobin} />
      </div>
    ),
  },
  {
    key: 7,
    label: 'TC',
    content: (
      <div className="h-full w-full">
        <span className="text-[#222222] px-4 text-sm md:text-base lg:text-base font-bold md:font-bold lg:font-bold">
          Cholestral
        </span>
        <LineChart data={cholestral} />
      </div>
    ),
  },
  {
    key: 8,
    label: 'UA',
    content: (
      <div className="h-full w-full">
        <span className="text-[#222222] px-4 text-sm md:text-base lg:text-base font-bold md:font-bold lg:font-bold">
          Uric Acid
        </span>
        <LineChart data={uricAcid} />
      </div>
    ),
  },
  {
    key: 9,
    label: 'All Symptoms',
    content: (
      <div className="w-full h-full">
        <span className="text-[#222222] px-4 text-sm md:text-base lg:text-base font-bold md:font-bold lg:font-bold">
          symptoms
        </span>
        <PieChart />
      </div>
    ),
  },
]
