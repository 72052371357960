import { BG, Hb, TC, UA } from '../assets/icons'

export interface BasicBloodTestDataType {
  icon: JSX.Element
  value: string
  content: string
}
export const BasicBloodTestData = [
  {
    key: 1,
    icon: <BG />,
    value: '149',
    content: 'mgl/mL',
  },
  {
    key: 2,
    icon: <Hb />,
    value: '149',
    content: 'gl/dl',
  },
  {
    key: 3,
    icon: <UA />,
    value: '149',
    content: 'mgl/mL',
  },
  {
    key: 4,
    icon: <TC />,
    value: '149',
    content: 'mg/dl',
  },
]
